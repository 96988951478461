import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Box from '@material-ui/core/Box';
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Send, Replay, Save } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import strategySubmit from 'services/submitStrategy';
import {
	strategyUpdate,
	additionalUpdate,
	saveDataBullet,
	resetBottomBullet,
	recentDataBullet,
	updateContext,
	updateContent,
} from 'store/modules/bottomBullet/actions';
import { store } from 'store';
import MaterialCheckBox from '../MaterialCheckBox';
import TimeInput from '../TimeInput';
import PasswordInput from '../PasswordInput';
import { GeneralButton } from '../MaterialStyles/style';
import { updateCustomParameters } from '../../../store/modules/bottomBullet/actions';
import { cypressSubmit } from 'services/cypressUtils';
import singleClear from './submitFunctions/singleClear';
import spreadClear from './submitFunctions/spreadClear';
import lgshortClear from './submitFunctions/lgshortClear';
import financClear from './submitFunctions/financClear';
import gradientClear from './submitFunctions/gradientClear';
import twapClear from './submitFunctions/twapClear';
import icebergClear from './submitFunctions/icebergClear';
import skewClear from './submitFunctions/skewClear';
import cvvolClear from './submitFunctions/cvvolClear';
import povClear from './submitFunctions/povClear';
import marketmakerClear from './submitFunctions/marketmakerClear';
import portbalanceClear from './submitFunctions/portbalanceClear';
import sorderEdit from './editFunctions/sorderEdit';
import fxorderEdit from './editFunctions/fxorderEdit';
import bofferEdit from './editFunctions/bofferEdit';
import mitEdit from './editFunctions/mitEdit';
import grdEdit from './editFunctions/grdEdit';
import icebergEdit from './editFunctions/icebergEdit';
import twapEdit from './editFunctions/twapEdit';
import spreadEdit from './editFunctions/spreadEdit';
import longshortEdit from './editFunctions/longshortEdit';
import skewEdit from './editFunctions/skewEdit';
import cvvolEdit from './editFunctions/cvvolEdit';
import povEdit from './editFunctions/povEdit';
import marketmakerEdit from './editFunctions/marketmakerEdit';
import { MaterialSnackbar } from '../MaterialSnackbar';
import {
	generalValidation,
	returnCorrectLabel,
} from './submitFunctions/generalValidation';
import {
	getDefaultBmfAssetList,
	getRevertedStrategyName,
	getStrategyName,
} from '../../../utils/strategies';
import { NEW, validateTypeBullet } from '../../../utils/bulletContext';
import { isDisableBySymbol } from '../../../utils/isDisableBySymbol';
import { TimeInForceInput } from './TimeInForceInput';
import { findShortestTime } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import { format, isAfter, lightFormat, set, sub } from 'date-fns';
import { RecurrenceDateInput } from './RecurrenceDateInput';
import { PRODUCT_TYPES } from 'utils/constants';
import { SavedOrderDialog } from 'components/SavedOrderDialog';
import { getGradientFormattedName } from 'helpers/gradient/customParameters';
import { gapClear, gapEdit } from './submitFunctions/gapClear';

const MaterialFooter = ({ closeBullet, setLastVol }) => {
	const dispatch = useDispatch();
	const { bullet, savedOrders } = useSelector(state => state.bottomBullet);
	const permitedAlgos = useSelector(state => state.auth.algos);
	const widgetOpen = useSelector(state => state.configs.widgetBar);
	const { t } = useTranslation();
	const { recentOrders } = useSelector(state => state.bottomBullet);
	const savingOrderLoading = useSelector(
		state => state.ui.savingOrderLoading
	);
	const portalAccess = useSelector(state => state.auth.portalAccess);
	const initialAccount = useSelector(state =>
		state.auth.accounts.length > 0
			? ![null, undefined, '', 'Nenhuma'].includes(
					state.configs.globalSettings?.userSettings?.bullet
						.defaultAccount
			  )
				? state.configs.globalSettings?.userSettings?.bullet
					.defaultAccount
				: state.auth.accounts[0]?.code
			: ''
	);
	const defaultAccount = useSelector(
		state =>
			state.configs.globalSettings?.userSettings?.bullet.defaultAccount
	);
	const { operatorCode, userType } = useSelector(state => state.auth);
	const oldExecType = useSelector(state => state.auth.execType);
	const newExecType = useSelector(
		state => state.configs.globalSettings?.userSettings?.bullet.execType
	);
	const currentStrategies = useSelector(
		state => state.currentStrategies.rows
	);
	const positions =
		currentStrategies?.filter(row => row.hierarchy.length === 1) ?? [];

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	const activeProduct = useSelector(state => state.products.activeProduct);
	const [isSavedOrderDialog, setIsSavedOrderDialog] = useState({
		isOpen: false,
		title: '',
		savedOrder: {},
	});

	const account = () => {
		return bullet.account ? bullet.account : initialAccount;
	};

	const handleUpdateRecentsOrders = bullet => {
		let tempRecents = [...recentOrders];

		const date = new Date().toLocaleDateString('pt-BR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		});
		const time = new Date().toLocaleTimeString('pt-BR', {
			hour12: false,
			hour: '2-digit',
			minute: '2-digit',
		});

		let strategyLegs = {};
		if (bullet.content.StrategyCode !== 'portbalance') {
			strategyLegs = {
				StrategyLegs: bullet.content.StrategyLegs.map(leg => {
					return { ...leg };
				}),
			};
		}

		tempRecents.unshift({
			...bullet.content,
			CustomParameters: { ...bullet.content.CustomParameters },
			...strategyLegs,
			StrategyInfos: bullet.StrategyInfos.map(info => {
				return { ...info };
			}),
			date: date,
			time: time,
			id: `${bullet.content.StrategyCode}-${bullet.content.Name}-${bullet.content.ExpireDate}`,
		});

		// pega sempre o 10 primeiros itens do array
		dispatch(
			recentDataBullet(
				tempRecents.slice(
					0,
					Math.min(Math.max(tempRecents.length - 1, 10), 10)
				)
			)
		);
	};

	const setAccessorInfos = legs => {
		if ((userType.id === 2 || userType.id === 5) && operatorCode) {
			legs.forEach((_, index) => {
				legs[index].LegEnteringTrader = operatorCode;
			});
		}
		return legs;
	};

	const setAccessorInfosOnCustomParams = customParameters => {
		if ((userType.id === 2 || userType.id === 5) && operatorCode) {
			customParameters.EnteringTrader = operatorCode;
		}
		return customParameters;
	};

	const validatePrice = price => {
		if (
			price === 0 ||
			price === '0.00' ||
			price === '0' ||
			price === '' ||
			isNaN(price) ||
			price === undefined
		) {
			return true;
		}
	};

	const validateHour = time => {
		return time.includes('_') || time === ':00' || time === '00:';
	};

	const haveNullDefaultAccount = () => {
		return (
			defaultAccount === '' ||
			defaultAccount === 'Nenhuma' ||
			defaultAccount === 'Selecione' ||
			defaultAccount === null ||
			defaultAccount === undefined
		);
	};

	const validateSelectedAccounts = (bullet, initialAccount, userType) => {
		if (!bullet.content.ClOrdID) {
			if (
				initialAccount.length === 0 &&
				userType.id !== 5 &&
				userType.id !== 2
			) {
				toast.error(
					'Não há contas cadastradas para este usuário. Entre em contato com suporte@investflex.com.br'
				);
				return;
			}
			let abort = false;
			bullet.content.StrategyLegs.every(leg => {
				let text = '';
				let accountBullet = undefined;
				accountBullet = bullet.account;
				const accountAuth = store
					.getState()
					.auth.accounts.filter(
						item => item.exchange === leg.LegSecurityExchange
					);
				//pego o a conta selecionada na boleta
				const selectedAccount = accountAuth.filter(
					account => account.code === accountBullet
				);
				//tem mais de uma conta e a conta selecionada nao existe ou nao tem conta
				if (
					(accountAuth.length > 1 &&
						(accountBullet === undefined ||
							selectedAccount.length === 0) &&
						haveNullDefaultAccount()) ||
					(accountAuth.length < 1 &&
						userType.id !== 5 &&
						userType.id !== 2 &&
						haveNullDefaultAccount()) ||
					bullet.account === 'Selecione' ||
					bullet.account === 'Nenhuma'
				) {
					toast.error(`Selecione uma conta ${text}`);
					abort = true;
					return false;
				} else if (
					(userType.id === 5 || userType.id === 2) &&
					(!bullet.account || bullet.account === '')
				) {
					toast.error(`Digite uma conta ${text}`);
					abort = true;
					return false;
				}
				return true;
			});

			return abort;
		}

		return false;
	};

	const getCleanedBullet = async (
		bullet,
		t,
		setAccessorInfos,
		setAccessorInfosOnCustomParams,
		account,
		execType,
		dispatch,
		validatePrice
	) => {
		const isInEditingMode = !!bullet.content.ClOrdID;

		switch (bullet.content.StrategyCode) {
			case 'sorder':
				return isInEditingMode
					? sorderEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
			case 'fxorder':
				return isInEditingMode
					? fxorderEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
			case 'spread2p':
			case 'spread3p':
			case 'spread4p':
			case 'spread5p':
			case 'spread6p':
			case 'spread':
				return isInEditingMode
					? spreadEdit(bullet)
					: spreadClear(bullet, setAccessorInfos, account, execType);
			case 'lgshort':
				return isInEditingMode
					? longshortEdit(bullet)
					: lgshortClear(bullet, setAccessorInfos, account, execType);
			case 'financ':
				return isInEditingMode
					? spreadEdit(bullet)
					: financClear(
						bullet,
						setAccessorInfos,
						account,
						t,
						execType
					  );
			case 'boffer':
				return isInEditingMode
					? bofferEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
			case 'mit':
				return isInEditingMode
					? mitEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
			case 'sniper':
				return isInEditingMode
					? sorderEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
			case 'grdlin':
			case 'grddin':
				return isInEditingMode
					? grdEdit(bullet)
					: gradientClear(
						bullet,
						setAccessorInfos,
						account,
						t,
						dispatch
					  );
			case 'iceberg':
				return isInEditingMode
					? icebergEdit(bullet)
					: icebergClear(
						bullet,
						setAccessorInfos,
						account,
						t,
						validatePrice
					  );
			case 'twap':
				return isInEditingMode
					? twapEdit(bullet)
					: twapClear(bullet, setAccessorInfos, account, t);
			case 'pov':
				return isInEditingMode
					? povEdit(bullet)
					: povClear(
						bullet,
						setAccessorInfos,
						account,
						t,
						validatePrice
					  );
			case 'cvvol':
				return isInEditingMode
					? cvvolEdit(bullet)
					: cvvolClear(bullet, setAccessorInfos, account);
			case 'skew':
				return isInEditingMode
					? skewEdit(bullet)
					: skewClear(bullet, setAccessorInfos, account);
			case 'marketmaker':
				return isInEditingMode
					? marketmakerEdit(bullet)
					: marketmakerClear(
						bullet,
						setAccessorInfos,
						account,
						t,
						validatePrice
					  );
			case 'portbalance':
				return await portbalanceClear(
					bullet,
					setAccessorInfosOnCustomParams,
					account,
					t,
					validatePrice
				);
			case 'gap':
				return isInEditingMode
					? gapEdit(bullet)
					: await gapClear(bullet, account);
			default:
				return isInEditingMode
					? sorderEdit(bullet, t)
					: singleClear(bullet, setAccessorInfos, account, t);
		}
	};

	const bulletSubmit = async () => {
		// Verifica se o account foi preenchido corretamente
		if (validateSelectedAccounts(bullet, initialAccount, userType)) {
			return;
		}

		if (!bullet.signature || bullet.signature === '') {
			if (portalAccess) {
				toast.error(
					'Assinatura inválida. Caso tenha alterado sua assinatura eletrônica no portal da Órama, realize novamente o login no FlexScan'
				);
			} else {
				toast.error('Assinatura inválida');
			}
			return;
		}

		if (!bullet.content.ExpireDate || bullet.content.ExpireDate === '') {
			toast.error('Nenhuma data de expiração registrada');
			return;
		}

		if (bullet.keepSignature) {
			localStorage.setItem('keep_signature', bullet.signature);
		}

		if (!bullet.keepSignature) {
			localStorage.setItem('keep_signature', false);
		}

		// Verifica se o usuario tem permissao de envio do algo
		const strategyCodeParsed =
			bullet.content.StrategyCode === 'spread2p'
				? 'spread'
				: bullet.content.StrategyCode;
		const haveStrategy = permitedAlgos.filter(
			item => item.api_code === strategyCodeParsed
		);
		if (haveStrategy.length === 0) {
			return dispatch(additionalUpdate('unauthorized', true));
		}

		// Caso seja edição, verifica o status da ordem e bloqueia o envio se for finalizada, cancelando ou cancelada
		if (bullet.content.ClOrdID) {
			const checkStatusOrder = positions.filter(
				item => item.ClOrdID === bullet.content.ClOrdID
			);

			if (
				checkStatusOrder.length === 1 &&
				[5, 6, 7].includes(parseInt(checkStatusOrder[0].OrdStatus))
			) {
				toast.error(
					'Não é possível enviar uma edição com o status atual da estratégia'
				);

				return;
			}
		}

		// Pega o json da boleta.
		const execTypeVerify = validateTypeBullet(
			bullet.content.CustomParameters.ExecStrategy,
			newExecType ?? oldExecType,
			bullet.context
		);

		const timeInForceInfos = {
			TimeInForce: bullet.content.TimeInForce,
			ExpireDate: bullet.content.ExpireDate,
		};

		const cleanedBullet = await getCleanedBullet(
			bullet,
			t,
			setAccessorInfos,
			setAccessorInfosOnCustomParams,
			account,
			execTypeVerify,
			dispatch,
			validatePrice
		);

		// Validando se a hora fim é menor que a hora início
		if (bullet.content.EndTime <= bullet.content.InitTime) {
			toast.error('Hora fim deve ser maior que a hora de início');
			return false;
		}

		if (cleanedBullet) {
			if (cleanedBullet.errorMessage) {
				toast.error(t(cleanedBullet.errorMessage));
				return;
			} else {
				//workaround to avoid null/undefined on LegSecurityExchange field
				if (cleanedBullet && cleanedBullet?.StrategyLegs) {
					const assetList = getDefaultBmfAssetList();
					cleanedBullet.StrategyLegs.forEach(leg => {
						if (
							leg &&
							[null, undefined, ''].includes(
								leg.LegSecurityExchange
							)
						) {
							if (
								leg.LegSymbol?.length > 2 &&
								assetList.includes(leg.LegSymbol.substr(0, 3))
							) {
								leg.LegSecurityExchange = 'XBMF';
							}
						}
					});
				}

				const haveNull = generalValidation(cleanedBullet);
				console.log('haveNull', haveNull);

				if (haveNull && haveNull.length > 0) {
					toast.error(
						`O campo ${returnCorrectLabel(
							haveNull
						)} precisa ser preenchido para o envio`
					);
					return;
				}
			}

			// Caso nao seja edicao, realiza as verificacoes abaixo
			if (!bullet.content.ClOrdID) {
				if (
					validateHour(bullet.content.InitTime) ||
					validateHour(bullet.content.EndTime)
				) {
					toast.error('Preencha os campos Hora Início e Fim');
					return;
				}

				if (cleanedBullet.Name === '') {
					cleanedBullet.Name = getStrategyName(bullet, positions);
				} else if (
					cleanedBullet.StrategyCode === 'grddin' &&
					bullet.subUid === 3
				) {
					cleanedBullet.Name = getGradientFormattedName(
						bullet.isMarketPrice,
						cleanedBullet.Name
					);
				}

				if (bullet.clone) {
					positions.forEach(position => {
						if (cleanedBullet.Name === position.Name) {
							cleanedBullet.Name = getStrategyName(
								bullet,
								positions
							);
							return;
						}
					});
				}

				if (bullet.revert) {
					cleanedBullet.Name = getRevertedStrategyName(
						bullet,
						positions
					);
				}
			}

			const formattedBullet = {
				...cleanedBullet,
				...timeInForceInfos,
				Signature: bullet.signature,
			};

			const recurrencesInfo = {
				...bullet.recurrenceInfo,
				isRecurrence: bullet.isRecurrence,
			};

			strategySubmit(formattedBullet, closeBullet, recurrencesInfo);

			if (!bullet.content.ClOrdID) {
				bullet.content = {
					...cleanedBullet,
					...timeInForceInfos,
				};

				handleUpdateRecentsOrders(bullet);
			}
		}
	};

	const changeKeepSignature = () => {
		dispatch(additionalUpdate('keepSignature', !bullet.keepSignature));
	};

	async function handleSaveNewOrder() {
		const ordersSaved = [...savedOrders];

		const orderSavedExists = ordersSaved.find(
			item => item.Name === bullet.content.Name
		);

		if (orderSavedExists) {
			setIsSavedOrderDialog({
				...isSavedOrderDialog,
				isOpen: true,
				savedOrder: {
					name: orderSavedExists.Name,
					strategyCode: orderSavedExists.StrategyCode,
					date: orderSavedExists.date,
					time: orderSavedExists.time,
				},
			});

			return;
		}

		await saveNewOrder();
	}

	const saveNewOrder = async () => {
		const execTypeVerify = validateTypeBullet(
			bullet.content.CustomParameters.ExecStrategy,
			newExecType ?? oldExecType,
			bullet.context
		);
		let newArray = [...savedOrders];

		const timeInForceInfos = {
			TimeInForce: bullet.content.TimeInForce,
			ExpireDate: bullet.content.ExpireDate,
		};

		let cleanedBullet = await getCleanedBullet(
			bullet,
			t,
			setAccessorInfos,
			setAccessorInfosOnCustomParams,
			account,
			execTypeVerify,
			dispatch,
			validatePrice
		);

		cleanedBullet = {
			...cleanedBullet,
			...timeInForceInfos,
			id: uuid(),
		};

		if (cleanedBullet) {
			const newBullet = {
				...cleanedBullet,
				StrategyInfos: [...bullet.StrategyInfos],
			};

			newBullet.date = new Date().toLocaleDateString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});

			newBullet.time = new Date().toLocaleTimeString('pt-BR', {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});

			if (newBullet.Name === '') {
				newBullet.Name = getStrategyName(bullet, positions);
			}

			newArray = newArray.filter(item => item.Name !== newBullet.Name);

			newArray.push(newBullet);

			if (window.Cypress) {
				cypressSubmit(cleanedBullet);
				return;
			}

			dispatch(saveDataBullet(newArray));
		}
	};

	const handleCloseOnSubmit = () => {
		dispatch(additionalUpdate('closeOnSubmit', !bullet.closeOnSubmit));
	};

	function handleCloseSaveDialog() {
		setIsSavedOrderDialog({
			...isSavedOrderDialog,
			isOpen: false,
		});
	}

	const handleSuspended = e => {
		dispatch(
			updateCustomParameters({
				InitSuspended: e.target.checked ? 'Y' : 'N',
			})
		);
	};

	const clearBottomBullet = () => {
		setLastVol({});
		resetBottomBullet(bullet);
		dispatch(additionalUpdate('isOpen', true));
		dispatch(updateContext(NEW));
		if (store.getState().auth.accounts?.length > 2) {
			dispatch(
				additionalUpdate('account', defaultAccount ?? 'Selecione')
			);
		} else if (userType.id === 5 || userType.id === 2) {
			dispatch(additionalUpdate('account', ''));
		}
	};

	const handleTime = (value, type) => {
		const hash = { ...bullet.content };
		if (type === 0) {
			hash.InitTime = value.length < 8 ? `${value}:00` : value;
		} else if (type === 1) {
			hash.EndTime = value.length < 8 ? `${value}:00` : value;
		}
		dispatch(strategyUpdate(hash));
	};

	const blockSendButton = newBullet => {
		return newBullet.content.StrategyLegs.filter((leg, index) => {
			return bullet.content.StrategyCode === 'skew' && index === 2
				? false
				: !bullet.StrategyInfos[index] ||
						bullet.StrategyInfos[index].length === 0 ||
						Object.keys(bullet.StrategyInfos[index]).length === 0;
		}).length > 0
			? true
			: false || bullet.disabledSubmit;
	};

	let expiredText = '';

	const verifyNewExpirationDate = () => {
		const strategyInfos = bullet.StrategyInfos;
		const intervalMinutes = parseInt(
			process.env.REACT_APP_INTERVAL_MINUTES ?? 2
		);

		const endTimeBullet = bullet.content.EndTime;
		const hourBullet = endTimeBullet ? endTimeBullet.substr(0, 2) : '17';
		const minuteBullet = endTimeBullet ? endTimeBullet.substr(3, 2) : '55';
		const dateTodayWithCloseHourBullet = set(new Date(), {
			hours: hourBullet,
			minutes: minuteBullet,
			seconds: 0,
		});

		const dateToday = lightFormat(new Date(), 'yyyyMMdd');

		return strategyInfos.some(info => {
			if (['OPT'].includes(info.securityType)) {
				if (info.maturityDate) {
					const maturityDate = info.maturityDate;

					if (maturityDate === dateToday) {
						const instrCloseHour = info.closeHour;
						const hour = instrCloseHour
							? instrCloseHour.substr(0, 2)
							: '17';
						const minute = instrCloseHour
							? instrCloseHour.substr(3, 2)
							: '55';
						const dateTodayWithCloseHour = set(new Date(), {
							hours: hour,
							minutes: minute,
							seconds: 0,
						});
						const dateTodayWithCloseHourDiscounted = sub(
							dateTodayWithCloseHour,
							{ minutes: intervalMinutes }
						);

						if (
							isAfter(
								dateTodayWithCloseHourBullet,
								dateTodayWithCloseHourDiscounted
							)
						) {
							expiredText = `As opções que vencem hoje só podem ser negociadas até as ${
								info.closeHour
							}.
								A Hora Fim deve estar programada com um horário até ${format(
		dateTodayWithCloseHourDiscounted,
		'HH:mm:00'
	)}. Por
								favor, realize a alteração para poder prosseguir.`;
							return true;
						}
					}
				}
			}

			return false;
		});
	};

	const expiredToday = verifyNewExpirationDate();
	const isDisabledBySymbol = isDisableBySymbol(bullet);

	if (bullet.content.isExpiredEndTime) {
		setSnackbarState({
			isOpen: true,
			severity: 'info',
			message: 'Hora fim ajustada devido a papel vencendo hoje!',
		});

		dispatch(
			strategyUpdate({ ...bullet.content, isExpiredEndTime: false })
		);
	}

	useEffect(() => {
		if (['NEW', 'REV', 'EXM'].includes(bullet.context)) {
			const endTimes = [];
			const initTimes = [];

			bullet.StrategyInfos.forEach(info => {
				endTimes.push(info.closeHour);
				initTimes.push(info.openHour);
			});

			store.dispatch(
				updateContent({
					EndTime: findShortestTime(endTimes, 1),
					InitTime: findShortestTime(initTimes, 2),
				})
			);

			verifyNewExpirationDate();
		}
	}, [bullet.StrategyInfos[0]?.closeHour, bullet.subUid, bullet.context]);

	return (
		<Box bgcolor="grey.A400" mx={-1} px={1} mt={1}>
			<MaterialSnackbar
				open={expiredToday}
				severity="warning"
				handleWidth="450px"
			>
				{expiredText}
			</MaterialSnackbar>
			<MaterialSnackbar
				open={bullet.content.isExpiredEndTime}
				autoHide={5000}
				handleClose={() => {
					const hash = { ...bullet.content };
					hash.isExpiredEndTime = false;
					dispatch(strategyUpdate(hash));
				}}
				severity="info"
				handleWidth="450px"
			>
				Hora fim ajustada devido a papel vencendo hoje!
			</MaterialSnackbar>
			<Grid
				container
				spacing={2}
				direction="row"
				justify="center"
				alignItems="flex-end"
				wrap="nowrap"
			>
				{!bullet.paramsView && (
					<Grid item xs>
						<Box textAlign="center">
							<GeneralButton
								name="clearButton"
								buttonColor={!isDisabledBySymbol && 'info'}
								fullWidth
								startIcon={<Replay />}
								onClick={clearBottomBullet}
								size="small"
								disabled={isDisabledBySymbol}
							>
								Limpar
							</GeneralButton>
						</Box>
					</Grid>
				)}

				<TimeInForceInput
					bullet={bullet}
					setSnackbarState={setSnackbarState}
					disableExpirationType={
						bullet.paramsView || bullet.content.ClOrdID
					}
					disableDateSelector={bullet.paramsView}
				/>

				{!['1', '6'].includes(bullet.content.TimeInForce) && (
					<>
						<Grid item xs style={{ minWidth: '105px' }}>
							<TimeInput
								tooltip={t('bullets.footer.init_time')}
								name="initTime"
								label="Hora Início"
								disabled={
									bullet.paramsView
										? true
										: bullet.content.ClOrdID
											? true
											: false
								}
								value={bullet.content.InitTime}
								onChange={e => handleTime(e.target.value, 0)}
							/>
						</Grid>

						{bullet.content.TimeInForce !== '1' && (
							<Grid item xs style={{ minWidth: '105px' }}>
								<TimeInput
									tooltip={t('bullets.footer.end_time')}
									name="endTime"
									label="Hora Fim"
									error={expiredToday}
									value={
										bullet.content.EndTime ||
										bullet.values.EndTime
									}
									onChange={e =>
										handleTime(e.target.value, 1)
									}
									disabled={bullet.paramsView}
								/>
							</Grid>
						)}
					</>
				)}

				{bullet.isRecurrence && (
					<Grid item xs>
						<RecurrenceDateInput
							key="ActiveEndDate"
							id="ActiveEndDate"
							name="ActiveEndDate"
							label="Repete até"
							value={bullet.recurrenceInfo.ActiveEndDate}
							disabled={!bullet.isRecurrence || bullet.paramsView}
						/>
					</Grid>
				)}

				<Grid
					item
					style={{
						minWidth: widgetOpen ? '110px' : '',
					}}
				>
					<MaterialCheckBox
						name="initSuspended"
						smallLabel
						label="Iniciar Suspensa"
						tooltip={t('bullets.footer.init_suspended')}
						checked={
							bullet.content.CustomParameters.InitSuspended ===
							'Y'
						}
						disabled={[
							'sorder',
							'fxorder',
							'grdlin',
							'grddin',
							'iceberg',
						].includes(bullet.content.StrategyCode)}
						onChange={e => handleSuspended(e)}
					/>
				</Grid>
				<Grid item xs style={{ maxWidth: 250 }}>
					<PasswordInput disabled={bullet.paramsView} />
				</Grid>
				<Grid
					item
					style={{
						minWidth: widgetOpen ? '125px' : '',
					}}
				>
					<MaterialCheckBox
						name="keepSignature"
						label="Lembrar Assinatura"
						tooltip={t('bullets.footer.keep_signature')}
						disabled={bullet.paramsView}
						checked={bullet.keepSignature}
						onChange={changeKeepSignature}
						smallLabel
					/>
				</Grid>
				<Grid item>
					<MaterialCheckBox
						name="closeCheckBox"
						label="Fechar"
						tooltip={t('bullets.footer.close_on_submit')}
						checked={bullet.closeOnSubmit}
						onChange={handleCloseOnSubmit}
						smallLabel
					/>
				</Grid>
				{!bullet.content.ClOrdID && (
					<Grid item xs={0}>
						{savingOrderLoading ? (
							<CircularProgress size={15} />
						) : (
							<Box textAlign="right">
								<Tooltip title="Salvar">
									<IconButton
										disabled={
											expiredToday ||
											isDisabledBySymbol ||
											activeProduct.code ===
												PRODUCT_TYPES.FLEX_ANALYTIC
										}
										aria-label="salvar"
										onClick={handleSaveNewOrder}
										size="small"
									>
										<Save />
									</IconButton>
								</Tooltip>
							</Box>
						)}
					</Grid>
				)}
				{!bullet.paramsView && (
					<Grid item xs>
						<GeneralButton
							data-cy="sendButton"
							variant="contained"
							buttonColor={
								blockSendButton(bullet) ||
								expiredToday ||
								activeProduct.code ===
									PRODUCT_TYPES.FLEX_ANALYTIC
									? 'disabled'
									: bullet.content.ClOrdID
										? 'warning'
										: 'success'
							}
							disabled={
								blockSendButton(bullet) ||
								expiredToday ||
								activeProduct.code ===
									PRODUCT_TYPES.FLEX_ANALYTIC
							}
							startIcon={<Send />}
							fullWidth
							onClick={bulletSubmit}
							size="small"
						>
							{bullet.content.ClOrdID
								? 'Enviar Edição'
								: 'Enviar'}
						</GeneralButton>
					</Grid>
				)}
			</Grid>

			<SavedOrderDialog
				savedOrderDialog={isSavedOrderDialog}
				handleSaveOrder={() => {
					saveNewOrder();
					handleCloseSaveDialog();
				}}
				handleClose={handleCloseSaveDialog}
			/>

			<MaterialSnackbar
				{...snackbarState}
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'error'}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Box>
	);
};

export default MaterialFooter;
