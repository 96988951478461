import React from 'react';

import { Box, Typography, colors } from '@mui/material';

import { AppAuthentication } from './AppAuthentication';
import { EmailAuthentication } from './EmailAuthentication';

export const Authentication = ({
	authToken,
	authMethod,
	setLoginStep,
	handleCancel,
	setAuthMethod,
	emailAuthProps,
}) => {
	return (
		<>
			<Box
				sx={{
					p: 2,
					gap: 3,
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant="subtitle1"
					sx={{ color: colors.grey[500] }}
				>
					Autenticação de dois fatores
				</Typography>

				{authMethod === 'email' && (
					<EmailAuthentication
						props={{
							...emailAuthProps,
							setLoginStep,
							handleCancel,
							setAuthMethod,
							authToken,
						}}
					/>
				)}

				{authMethod === 'app' && (
					<AppAuthentication
						authToken={authToken}
						handleCancel={handleCancel}
						setLoginStep={setLoginStep}
						setAuthMethod={setAuthMethod}
					/>
				)}
			</Box>
		</>
	);
};
