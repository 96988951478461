import { format } from 'date-fns';
import { toast } from 'react-toastify';

function getEntranceMoment(customParameters, strategyLegs) {
	const { RelativeType, RelativeDirection } = customParameters;
	const legSide = strategyLegs[0].LegSide;

	const entranceMomentValues = {
		start: 'S',
		limitedImmediate: 'I',
		limitedNextToTarget: 'N',
		openAuction: 'A',
	};

	const relativeTypeValues = {
		openingCurrentDay: 'O',
		prevClosePrice: 'C',
		minCurrentDay: 'L',
		maxCurrentDay: 'H',
	};

	const openingOrPrevClose = [
		relativeTypeValues.openingCurrentDay,
		relativeTypeValues.prevClosePrice,
	].includes(RelativeType);

	if (openingOrPrevClose && RelativeDirection === 'D') {
		if (legSide === '2') {
			return entranceMomentValues.start;
		}

		return entranceMomentValues.limitedImmediate;
	}

	if (openingOrPrevClose && RelativeDirection === 'U') {
		if (legSide === '1') {
			return entranceMomentValues.start;
		}

		return entranceMomentValues.limitedImmediate;
	}

	return entranceMomentValues.limitedImmediate;
}

function getFormattedCustomParameters(customParameters, strategyLegs) {
	const formattedCustomParameters = {};

	let timeFields = {};
	let exitFields = {};
	let slippageFields = {};

	const entranceMoment = getEntranceMoment(customParameters, strategyLegs);

	for (const [key, value] of Object.entries(customParameters)) {
		if (
			![null, undefined, NaN].includes(customParameters[key]) &&
			!String(key).startsWith('_')
		) {
			formattedCustomParameters[key] = value;
		}
	}

	if (entranceMoment === 'S') {
		slippageFields = {
			PxRelSlippagePts: formattedCustomParameters.PxRelSlippagePts,
		};
	}

	if (formattedCustomParameters.PxInitTimeMonitoring) {
		timeFields.PxInitTimeMonitoring = format(
			formattedCustomParameters.PxInitTimeMonitoring,
			'HH:mm:ss'
		);

		if (formattedCustomParameters.PxEndTimeMonitoring) {
			timeFields.PxEndTimeMonitoring = format(
				formattedCustomParameters.PxEndTimeMonitoring,
				'HH:mm:ss'
			);
		}
	}

	if (formattedCustomParameters.ExitPxRelLoss) {
		exitFields.ExitPxRelLoss = formattedCustomParameters.ExitPxRelLoss;
		exitFields.ExitUnit = formattedCustomParameters.ExitUnit;

		slippageFields = {
			...slippageFields,
			ExitPxSlippagePts: formattedCustomParameters.PxRelSlippagePts,
		};
	}

	if (formattedCustomParameters.ExitPxRelGain) {
		exitFields.ExitPxRelGain = formattedCustomParameters.ExitPxRelGain;
		exitFields.ExitUnit = formattedCustomParameters.ExitUnit;
	}

	if (formattedCustomParameters.ExitOnEndTime === 'Y') {
		exitFields.ExitOnEndTime = formattedCustomParameters.ExitOnEndTime;
	}

	if (formattedCustomParameters.ExitOnEndDayAuction === 'Y') {
		exitFields.ExitOnEndDayAuction =
			formattedCustomParameters.ExitOnEndDayAuction;
	}

	return {
		TargetPrice: formattedCustomParameters.TargetPrice,
		RelativeType: formattedCustomParameters.RelativeType,
		RelativeDirection: formattedCustomParameters.RelativeDirection,
		RelativeUnit: formattedCustomParameters.RelativeUnit,
		RelativePxRel: formattedCustomParameters.RelativePxRel,
		EntranceMoment: entranceMoment,
		...timeFields,
		...exitFields,
		...slippageFields,
	};
}

function verifyEmptyMandatoryFields(objectToVerify) {
	const possibleMandatoryFieldsMapping = {
		TargetPrice: 'Preço alvo',
		ExitPxSlippagePts: 'Slippage',
		ExitPxRelLoss: 'Loss',
		ExitUnit: 'Seletor de pontos ou porcentagem da saída',
		ExitPxRelGain: 'Gain',
		FixedPrice: 'Preço fixo',
		RelativeDirection: 'Direção relativa',
		RelativeType: 'Tipo relativo',
		RelativeUnit: 'Unidade relativa',
		RelativePxRel: 'Preço relativo',
		PxInitTimeMonitoring: 'horário de inicial para envio',
		PxEndTimeMonitoring: 'horário de final para envio ',
		PxRelSlippagePts: 'Slippage',
	};

	let emptyFieldForMessage;

	const exitMandatoryParams = [];
	const timeMandatoryParams = [];

	if (objectToVerify.ExitPxRelLoss) {
		exitMandatoryParams.push('ExitPxRelLoss', 'ExitUnit');
	}

	if (objectToVerify.ExitPxRelGain) {
		exitMandatoryParams.push('ExitPxRelGain', 'ExitUnit');
	}

	if (objectToVerify._relativePxInitTimeOption === 'specified') {
		timeMandatoryParams.push('PxInitTimeMonitoring');
	}

	if (objectToVerify._relativePxInitTimeOption === 'interval') {
		timeMandatoryParams.push('PxInitTimeMonitoring', 'PxEndTimeMonitoring');
	}

	const mandatoryCustomParameterFields = [
		'PxRelSlippagePts',
		'RelativeDirection',
		'RelativeType',
		'RelativeUnit',
		'RelativePxRel',
		...exitMandatoryParams,
		...timeMandatoryParams,
	];

	const hasCustomParameterFieldEmpty = mandatoryCustomParameterFields.some(
		field => {
			if ([null, undefined, ''].includes(objectToVerify[field])) {
				emptyFieldForMessage = field;
				return true;
			}
			return false;
		}
	);

	if (hasCustomParameterFieldEmpty) {
		toast.error(
			`Campo obrigatório de "${possibleMandatoryFieldsMapping[emptyFieldForMessage]}" não preenchido`
		);

		return true;
	}

	return false;
}

function getFormattedStrategyLegs(bullet, account, useApparentQuantity) {
	const strategyLeg = bullet.StrategyLegs[0];

	const legMinClipSize = useApparentQuantity
		? { LegMinClipSize: strategyLeg.LegMinClipSize }
		: {};

	return [
		{
			ILegAllocAccount: account,
			LegQuantity: strategyLeg.LegQuantity,
			LegSecurityExchange: strategyLeg.LegSecurityExchange,
			LegSide: strategyLeg.LegSide,
			LegSymbol: strategyLeg.LegSymbol,
			...legMinClipSize,
		},
	];
}

export function gapClear(bullet, account) {
	const { content } = bullet;

	const hasMandatoryFieldEmpty = verifyEmptyMandatoryFields(
		content.CustomParameters
	);

	if (hasMandatoryFieldEmpty) {
		return;
	}

	const accountToBeUsed = account();

	const strategyLegs = getFormattedStrategyLegs(
		content,
		accountToBeUsed,
		content.CustomParameters._useApparentQuantity
	);

	const formattedCustomParameters = getFormattedCustomParameters(
		content.CustomParameters,
		strategyLegs
	);

	return {
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: formattedCustomParameters,
		StrategyLegs: strategyLegs,
		Signature: content.Signature,
	};
}

function getEditedLegs(strategyLeg, useApparentQuantity) {
	const legMinClipSize = useApparentQuantity
		? { LegMinClipSize: strategyLeg.LegMinClipSize }
		: {};

	return [
		{
			LegRefID: strategyLeg.LegRefID,
			LegQuantity: strategyLeg.LegQuantity,
			...legMinClipSize,
		},
	];
}

export function gapEdit(bullet) {
	const { content } = bullet;

	const strategyLegs = getEditedLegs(
		bullet.content.StrategyLegs[0],
		bullet.content.CustomParameters._useApparentQuantity
	);

	const formattedCustomParameters = getFormattedCustomParameters(
		content.CustomParameters,
		strategyLegs
	);

	delete formattedCustomParameters.TargetPrice;

	return {
		ClOrdID: bullet.content.ClOrdID,
		Name: content.Name,
		InitTime: content.InitTime,
		EndTime: content.EndTime,
		ExpireDate: content.ExpireDate,
		BasketId: content.BasketId,
		StrategyCode: content.StrategyCode,
		CustomParameters: formattedCustomParameters,
		StrategyLegs: strategyLegs,
		Signature: content.Signature,
	};
}
