import { apiAWS } from './api';

export async function sendValidationCode(userName, token) {
	try {
		const body = {
			user_input: userName,
			service: 'mfa_signin',
		};

		const headers = {
			headers: {
				'x-authorization': process.env.REACT_APP_AWS_API_TOKEN,
				Authorization: `Bearer ${token}`,
			},
		};

		const { data } = await apiAWS.post(
			'/credentials/tokens',
			body,
			headers
		);

		return data;
	} catch (error) {
		console.error(error);

		return null;
	}
}
