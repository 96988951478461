import { toast } from 'react-toastify';
import validatePrice from '../../validatePrice';

const cleanFxorderCustom = (hash, subUid, minIncrement, t) => {
	hash.CustomParameters.UseLevel3 =
        !hash.CustomParameters.UseLevel3 ||
        hash.CustomParameters.UseLevel3 === 'N'
        	? 'N'
        	: 'Y';
	hash.CustomParameters.StopType = hash.CustomParameters.StopType
		? parseInt(hash.CustomParameters.StopType)
		: 1;
	hash.CustomParameters.TriggerEntryType = hash.CustomParameters
		.TriggerEntryType
		? parseInt(hash.CustomParameters.TriggerEntryType)
		: 2;
	hash.CustomParameters.ReversionType = hash.CustomParameters.ReversionType ?? 3;

	if (validatePrice(hash.CustomParameters.PriceLimit) && subUid !== 2) {
		toast.error(t('O Preço limite precisa ser maior que 0'));
		return;
	}
	if (hash.CustomParameters.StopType === 3) {
		const useLevel3 = hash.CustomParameters.UseLevel3 === 'Y';

		// nao usa level 3
		if (!useLevel3) {
			// validacao de quantidades
			if (
				hash.CustomParameters.OrderQuantityL1 <= 0 ||
                hash.CustomParameters.OrderQuantityL2 <= 0
			) {
				toast.error(
					'Os campos de quantidades de gain parcial devem ser maiores que zero!',
				);
				return;
			}
			const somaQtyGains =
                hash.CustomParameters.OrderQuantityL1 +
                hash.CustomParameters.OrderQuantityL2;
			if (+hash.CustomParameters.OrderQuantity !== somaQtyGains) {
				toast.error(
					'A soma das quantidades de gain parcial devem corresponder da ordem de entrada',
				);
				return;
			}

			// validacao de precos
			if (
				hash.CustomParameters.StopGainL1 >=
                hash.CustomParameters.StopGainL2
			) {
				toast.error(
					'Os valores de Gain das parciais devem ser crescentes com relação ao nível anterior',
				);
				return;
			}
		} else {
			// usa level 3

			// validacao de quantidades
			if (
				hash.CustomParameters.OrderQuantityL1 <= 0 ||
                hash.CustomParameters.OrderQuantityL2 <= 0 ||
                hash.CustomParameters.OrderQuantityL3 <= 0
			) {
				toast.error(
					'Os campos de quantidades de gain parcial devem ser maiores que zero!',
				);
				return;
			}
			const somaQtyGains =
                hash.CustomParameters.OrderQuantityL1 +
                hash.CustomParameters.OrderQuantityL2 +
                hash.CustomParameters.OrderQuantityL3;
			if (+hash.CustomParameters.OrderQuantity !== somaQtyGains) {
				toast.error(
					'A soma das quantidades de gain parcial devem corresponder da ordem de entrada',
				);
				return;
			}

			// validacao de precos
			if (
				hash.CustomParameters.StopGainL1 >=
                    hash.CustomParameters.StopGainL2 ||
                hash.CustomParameters.StopGainL2 >=
                    hash.CustomParameters.StopGainL3
			) {
				toast.error(
					'Os valores de Gain das parciais devem ser crescentes com relação ao nível anterior',
				);
				return;
			}
		}
	}

	let cleanCustomParameters;
	if (hash.CustomParameters.StopType === 3) {
		cleanCustomParameters = {
			OrderQuantity: +hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.ReversionType,
			StopType: hash.CustomParameters.StopType,
			TriggerEntryType: parseInt(hash.CustomParameters.TriggerEntryType),
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			UseLevel3: hash.CustomParameters.UseLevel3,
			StopGainL1: parseFloat(hash.CustomParameters.StopGainL1),
			OrderQuantityL1: parseFloat(hash.CustomParameters.OrderQuantityL1),
			StopGainL2: parseFloat(hash.CustomParameters.StopGainL2),
			OrderQuantityL2: parseFloat(hash.CustomParameters.OrderQuantityL2),
			StartPriceTrigger: parseFloat(hash.CustomParameters.PriceLimit),
		};
		if (cleanCustomParameters.UseLevel3 === 'Y') {
			cleanCustomParameters.StopGainL3 = parseFloat(
				hash.CustomParameters.StopGainL3,
			);
			cleanCustomParameters.OrderQuantityL3 = parseFloat(
				hash.CustomParameters.OrderQuantityL3,
			);
		}
	} else if (hash.CustomParameters.StopType === 1) {
		cleanCustomParameters = {
			OrderQuantity: +hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.ReversionType,
			StopType: hash.CustomParameters.StopType,
			TriggerEntryType: parseInt(hash.CustomParameters.TriggerEntryType),
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			StopGain: parseFloat(hash.CustomParameters.StopGain),
			StartPriceTrigger: parseFloat(hash.CustomParameters.PriceLimit),
			UseLevel3: 'N',
		};
	} else {
		cleanCustomParameters = {
			OrderQuantity: +hash.CustomParameters.OrderQuantity,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			ReversionType: hash.CustomParameters.ReversionType,
			StopType: hash.CustomParameters.StopType,
			TriggerEntryType: parseInt(hash.CustomParameters.TriggerEntryType),
			StopLoss: parseFloat(hash.CustomParameters.StopLoss),
			StartPriceTrigger: parseFloat(hash.CustomParameters.PriceLimit),
			UseLevel3: 'N',
		};
	}
	if (hash.CustomParameters.CustomOrdType === 3) {
		delete cleanCustomParameters.StartPriceTrigger;
		cleanCustomParameters.EntranceExecPrice = parseFloat(
			hash.CustomParameters.PriceLimit,
		);
	}
	if (
		hash.CustomParameters.CustomOrdType === 2 &&
        !hash.CustomParameters.stop
	) {
		cleanCustomParameters = {
			StopType: 1,
			UseAuctionOpenPrice: hash.CustomParameters.UseAuctionOpenPrice
				? 'Y'
				: 'N',
			ReversionType: 4,
			CustomOrdType: hash.CustomParameters.CustomOrdType,
			UseLevel3: 'N',
			OrderQuantity: +hash.CustomParameters.OrderQuantity,
			TriggerEntryType: 1,
			StopLoss: minIncrement,
			StartPriceTrigger: parseFloat(hash.CustomParameters.PriceLimit),
		};
	}
	if (hash.CustomParameters.CustomOrdType === 1) {
		delete cleanCustomParameters.StartPriceTrigger;
	}
	return cleanCustomParameters;
};

export default cleanFxorderCustom;
