import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { ptBR } from 'date-fns/locale';

import { Book, Info } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	colors,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';

import { NumericInput } from 'components/Bullet/NumericInput';
import { LegSideButton } from 'components/Bullet/LegSideButton';
import ComboBoxInput from 'components/MaterialComponents/ComboBoxInput';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';

import {
	updateCustomParameters,
	updateStrategyLeg,
} from 'store/modules/bottomBullet/actions';
import { setPaperInfo } from 'utils/setPaperInfo';
import paperValidator from 'utils/paperValidation';
import { symbolOpenBook } from 'utils/symbolOpenBook';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import { GapConfigParams } from './GapConfigParams';
import { isValid, parse } from 'date-fns';

export const GapEntryParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);
	const widgetIsLoading = useSelector(state => state.configs.widgetIsLoading);

	const { suggestions, content, StrategyInfos } = bullet;

	const bulletLeg = content.StrategyLegs[0];
	const isEdition = content.ClOrdID;

	const {
		PxInitTimeMonitoring,
		PxEndTimeMonitoring,
		_useApparentQuantity,
		_relativePxInitTimeOption,
	} = content.CustomParameters;

	const { minOrderQty, roundLot } = StrategyInfos[0];

	const PxInitTimeMonitoringOptions = [
		{ value: 'immediate', label: 'Imediato' },
		{ value: 'specified', label: 'Horário específico' },
		{ value: 'interval', label: 'No intervalo' },
	];

	function handleUpdateStrategyLeg(field, value) {
		dispatch(updateStrategyLeg({ [field]: value }, 0));
	}

	function handleToggleUseApparentQuantity() {
		handleUpdateCustomParameters(
			'_useApparentQuantity',
			!_useApparentQuantity
		);
		handleUpdateStrategyLeg('LegMinClipSize', null);
	}

	function getPaperOptions() {
		if (suggestions?.source) {
			return {
				value: suggestions.source.id,
				title: suggestions.source.symbol,
			};
		}

		return suggestions;
	}

	const paperOptions = getPaperOptions();

	function handleUpdateCustomParameters(field, value) {
		dispatch(
			updateCustomParameters({
				[field]: value,
			})
		);
	}

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	const isBulletDisabledBySingleSideButton = paperValidator(
		StrategyInfos[0],
		bullet,
		'singleSideButtom'
	);

	function handleChangeRelativePxTimeMonitoringOption(value) {
		dispatch(
			updateCustomParameters({
				PxInitTimeMonitoring: null,
				PxEndTimeMonitoring: null,
				_relativePxInitTimeOption: value,
			})
		);
	}

	function handleChangeTime(field, date) {
		if (
			field === 'PxInitTimeMonitoring' &&
			_relativePxInitTimeOption === 'specified'
		) {
			dispatch(
				updateCustomParameters({
					PxInitTimeMonitoring: date,
					PxEndTimeMonitoring: date,
				})
			);

			return;
		}

		handleUpdateCustomParameters(field, date);
	}

	function handleBlurTime(field, value) {
		if (value.length === 6) {
			const parsedDate = parse(`${value}00`, 'HH:mm:ss', new Date());

			if (isValid(parsedDate)) {
				handleChangeTime(field, parsedDate);
			}
		}
	}

	const quantityFieldSize =
		(_useApparentQuantity ? 1.5 : 2.5) +
		(_relativePxInitTimeOption === 'immediate' ? 1 : 0);

	const relativeTimeSelectorSize =
		_relativePxInitTimeOption === 'immediate'
			? 3
			: _relativePxInitTimeOption === 'specified'
				? 2.25
				: 1.5;

	return (
		<Grid
			item
			container
			gap={2}
			direction="column"
			justifyContent="space-between"
		>
			<LocalizationProvider
				adapterLocale={ptBR}
				dateAdapter={AdapterDateFns}
			>
				<Grid
					item
					container
					sx={{
						p: 1,
						gap: 1,
						flex: 1,
						borderRadius: 1,
						flexDirection: 'column',
						justifyContent: 'space-evenly',
						bgcolor: colors.grey[900],
					}}
				>
					<Box
						sx={{
							display: 'flex',
							gap: 1,
						}}
					>
						<Typography variant="subtitle1">
							Parâmetros de entrada
						</Typography>

						<LightHTMLTooltip
							element={
								<Info fontSize="small" sx={{ mt: 0.25 }} />
							}
						>
							<DescriptionTooltip
								label="Início de range"
								description={t(
									'bullets.gradient.entrance_price'
								)}
							/>
						</LightHTMLTooltip>
					</Box>

					<Grid item container spacing={1}>
						<Grid
							item
							xs={
								(_useApparentQuantity ? 1.5 : 2) +
								(_relativePxInitTimeOption === 'immediate'
									? 0.5
									: 0)
							}
							sx={{
								display: 'flex',
								gap: 0.125,
								alignItems: 'flex-end',
							}}
						>
							<Tooltip title="Abrir book">
								<IconButton
									size="small"
									onClick={() =>
										addWidgetFromButton(bulletLeg.LegSymbol)
									}
									disabled={
										symbolOpenBook(
											StrategyInfos[0],
											bullet
										) || widgetIsLoading
									}
								>
									<Book />
								</IconButton>
							</Tooltip>

							<ComboBoxInput
								label="Ativo"
								name="paperField"
								isPaper
								comboOptions={paperOptions}
								onChange={e =>
									onSuggestionsFetchRequested(
										e.target.value,
										false,
										false,
										0
									)
								}
								value={String(
									bulletLeg.LegRefSymbol ??
										bulletLeg.LegSymbol
								).toUpperCase()}
								disabled={isEdition}
								setPaperInfo={setPaperInfo}
							/>
						</Grid>

						<Grid
							item
							xs={_useApparentQuantity ? 1.5 : 2}
							sx={{ display: 'flex', alignItems: 'flex-end' }}
						>
							<LegSideButton
								sx={{ height: 25 }}
								legSide={bulletLeg.LegSide}
								onClick={() =>
									handleUpdateStrategyLeg(
										'LegSide',
										bulletLeg.LegSide === '1' ? '2' : '1'
									)
								}
								disabled={isBulletDisabledBySingleSideButton}
							/>
						</Grid>

						<Grid item xs={quantityFieldSize} sx={{ mt: 1.25 }}>
							<NumericInput
								label="Quantidade"
								value={bulletLeg.LegQuantity ?? ''}
								disabled={isBulletDisabled}
								stepSize={roundLot || minOrderQty}
								minValue={minOrderQty ?? 0}
								roundOnBlur
								onChangeCallback={value =>
									handleUpdateStrategyLeg(
										'LegQuantity',
										value
									)
								}
							/>
						</Grid>

						<Grid
							item
							xs={_useApparentQuantity ? 3 : 1}
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
							}}
						>
							<Grid item xs={_useApparentQuantity ? 4 : true}>
								<FormControlLabel
									control={<Checkbox />}
									label="Qtd. aparente"
									labelPlacement="top"
									sx={{ mx: 0, mb: -0.5 }}
									checked={_useApparentQuantity}
									onChange={handleToggleUseApparentQuantity}
									disabled={isBulletDisabled}
								/>
							</Grid>

							{_useApparentQuantity && (
								<Grid item xs>
									<NumericInput
										label="Valor qtd. aparente"
										value={bulletLeg.LegMinClipSize ?? ''}
										disabled={isBulletDisabled}
										stepSize={roundLot || minOrderQty}
										minValue={minOrderQty ?? 0}
										roundOnBlur
										onChangeCallback={value =>
											handleUpdateStrategyLeg(
												'LegMinClipSize',
												value
											)
										}
									/>
								</Grid>
							)}
						</Grid>

						<Grid
							item
							xs={relativeTimeSelectorSize}
							sx={{ mt: 1.5 }}
						>
							<FormControl fullWidth disabled={isBulletDisabled}>
								<label htmlFor="PxInitTimeMonitoringSelector">
									Envio de ordem
								</label>
								<TextField
									value={_relativePxInitTimeOption}
									SelectProps={{ native: 'native' }}
									fullWidth
									disabled={isBulletDisabled}
									size="small"
									id="PxInitTimeMonitoringSelector"
									InputLabelProps={{ shrink: true }}
									select
									InputProps={{ style: { height: 25 } }}
									onChange={event =>
										handleChangeRelativePxTimeMonitoringOption(
											event.target.value
										)
									}
								>
									{PxInitTimeMonitoringOptions.map(
										account => (
											<option
												key={account.value}
												value={account.value}
											>
												{account.label}
											</option>
										)
									)}
								</TextField>
							</FormControl>
						</Grid>

						{['interval', 'specified'].includes(
							_relativePxInitTimeOption
						) && (
							<Grid
								item
								xs={
									_relativePxInitTimeOption === 'specified'
										? 2.25
										: 1.5
								}
								sx={{ mt: 1.5 }}
							>
								<FormControl fullWidth>
									<label htmlFor="init-time">
										{_relativePxInitTimeOption ===
										'interval'
											? 'De'
											: 'Às'}
									</label>
									<TimePicker
										id="PxInitTimeMonitoring"
										ampm={false}
										openTo="hours"
										inputFormat="HH:mm:ss"
										InputProps={{
											style: { height: 25 },
											onBlur: event =>
												handleBlurTime(
													'PxInitTimeMonitoring',
													event.target.value
												),
										}}
										views={['hours', 'minutes', 'seconds']}
										onChange={date => {
											handleChangeTime(
												'PxInitTimeMonitoring',
												date
											);
										}}
										disabled={isBulletDisabled}
										value={PxInitTimeMonitoring}
										renderInput={params => (
											<TextField
												id="init-time"
												size="small"
												{...params}
											/>
										)}
									/>
								</FormControl>
							</Grid>
						)}

						{_relativePxInitTimeOption === 'interval' && (
							<Grid item xs={1.5} sx={{ mt: 1.5 }}>
								<FormControl fullWidth>
									<label htmlFor="end-time">Até</label>
									<TimePicker
										id="PxEndTimeMonitoring"
										value={PxEndTimeMonitoring}
										ampm={false}
										openTo="hours"
										inputFormat="HH:mm:ss"
										InputProps={{
											style: { height: 25 },
											onBlur: event =>
												handleBlurTime(
													'PxEndTimeMonitoring',
													event.target.value
												),
										}}
										views={['hours', 'minutes', 'seconds']}
										onChange={date =>
											handleChangeTime(
												'PxEndTimeMonitoring',
												date
											)
										}
										disabled={isBulletDisabled}
										renderInput={params => (
											<TextField
												id="end-time"
												size="small"
												{...params}
											/>
										)}
									/>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</Grid>

				<GapConfigParams />
			</LocalizationProvider>
		</Grid>
	);
};
