const INIT_TIME = '09:00:00';
export const END_TIME = '16:53:00';
const LEG_SECURITY_EXCHANGE = 'XBSP';
const TEXT = 'A comment for my strategy';
const BASKET_ID = 'fxs';
const EXPIRE_DATE = '20191801';
const TIME_IN_FORCE = '0';

const strategies = {
	sorder: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		StrategyCode: 'sorder',
		Accounts: '',
		TimeInForce: TIME_IN_FORCE,
		CustomParameters: {
			PriceLimit: 0.0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegQuantity: 0,
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegSymbol: '',
				LegOrdType: '2',
			},
		],
	},
	sorderEditing: {
		StrategyCode: 'sorder',
		CustomParameters: {
			PriceLimit: '26.54',
		},
		StrategyLegs: [
			{
				LegQuantity: '200',
				LegOrdType: '2',
				LegRefID: '2ff85ece-ade0-48c6-9b68-8697d0ededdc:1',
			},
		],
	},
	lgshort: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		TimeInForce: TIME_IN_FORCE,
		StrategyCode: 'lgshort',
		Accounts: '',
		CustomParameters: {
			ExecutionType: 0,
			QuantityMode: 0,
			ToMarketTriggerValueEnabled: 'N',
			ToMarketTriggerValue: null,
			TriggerValue: null,
			Trigger: 2,
			CompensateExec: 2,
			BookDepth: 1,
			IgnoreOffersLT: 0,
			ExecStrategy: '',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegQuantity: 0,
				LegPrice: 0,
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegSymbol: '',
				LegResting: 'N',
				LegMaxClipSize: 0,
			},
			{
				ILegAllocAccount: '',
				LegQuantity: 0,
				LegPrice: 0,
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegSymbol: '',
				LegResting: 'N',
				LegMaxClipSize: 0,
			},
		],
	},
	lgshortEditing: {
		StrategyCode: 'lgshort',
		CustomParameters: {
			BookDepth: '1',
			IgnoreOffersLT: '0',
			ToMarketTriggerValue: '0.1',
			TriggerValue: '0.04',
		},
		StrategyLegs: [
			{
				LegMaxClipSize: '100',
				LegQuantity: '500',
				LegRefID: '9db2cc0f-ddf7-46ae-a406-bdd46f74b1ef:1',
				LegResting: 'Y',
			},
			{
				LegMaxClipSize: '500',
				LegQuantity: '500',
				LegRefID: '9db2cc0f-ddf7-46ae-a406-bdd46f74b1ef:2',
				LegResting: 'N',
			},
		],
	},
	spread: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'spread',
		Accounts: '',
		CustomParameters: {
			ExecutionType: 0,
			ToMarketTriggerValueEnabled: 'N',
			ToMarketTriggerValue: null,
			TriggerValue: null,
			Trigger: 1,
			CompensateExec: 2,
			BookDepth: 1,
			BandPriceLow: 0.01,
			BandPriceHigh: 0.01,
			BandPriceSymbol: '',
			BandPriceRef: 'N',
			BandPriceExchange: '',
			TriggerType: 1,
			ToMarkedType: 1,
			IgnoreOffersLT: 1,
			ExecStrategy: '',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'N',
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'N',
			},
		],
	},
	spreadEditing: {
		StrategyCode: '',
		CustomParameters: {
			ToMarketTriggerValue: 0,
			TriggerValue: 30,
			BandPriceHigh: 0,
			BandPriceLow: 0,
			IgnoreOffersLT: 0,
			BookDepth: 1,
		},
		StrategyLegs: [
			{
				LegRefID: '5f0a20fb-231e-4f37-a06d-618c391de2c6:1',
				LegQuantity: '400',
				LegResting: 'Y',
				LegMaxClipSize: '100',
				LegFirstTimeOut: '5000',
			},
			{
				LegRefID: '5f0a20fb-231e-4f37-a06d-618c391de2c6:2',
				LegQuantity: '200',
				LegResting: 'N',
				LegMaxClipSize: '100',
				LegFirstTimeOut: '5000',
			},
		],
	},
	financ: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'financ',
		Accounts: '',
		CustomParameters: {
			ExecutionType: 0,
			ToMarketTriggerValueEnabled: 'N',
			ToMarketTriggerValue: 0,
			Trigger: 16,
			TriggerValue: null,
			CompensateExec: 2,
			BookDepth: 1,
			IgnoreOffersLT: 0,
			ExecStrategy: '',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegResting: 'N',
				LegMaxClipSize: 0,
				LegFirstTimeOut: 5000,
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegResting: 'N',
				LegMaxClipSize: 0,
				LegFirstTimeOut: 5000,
			},
		],
	},
	financEditing: {
		StrategyCode: 'financ',
		CustomParameters: {
			ToMarketTriggerValue: 0,
			TriggerValue: 30,
		},
		StrategyLegs: [
			{
				LegRefID: '9f960629-114d-49ca-a2ec-9e52ccf6f21a:1',
				LegQuantity: '400',
				LegResting: 'Y',
				LegMaxClipSize: '100',
				LegFirstTimeOut: '5000',
			},
			{
				LegRefID: '9f960629-114d-49ca-a2ec-9e52ccf6f21a:2',
				LegQuantity: '200',
				LegResting: 'N',
				LegMaxClipSize: '100',
				LegFirstTimeOut: '5000',
			},
		],
	},
	fxorder: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		TimeInForce: TIME_IN_FORCE,
		StrategyCode: 'fxorder',
		Accounts: '',
		CustomParameters: {
			stop: true,
			PriceLimit: 0,
			OrderQuantity: 0,
			CustomOrdType: 1,
			StartPriceTrigger: 0,
			EntranceExecPrice: 0,
			ReversionType: 1, // APENAS NO GAIN OU LOSS
			StopType: 1,
			TriggerEntryType: 1,
			StopLoss: 0,
			StopGain: 0,
			UseLevel3: 'N',
			StopGainL1: 0,
			OrderQuantityL1: 0,
			StopGainL2: 0,
			OrderQuantityL2: 0,
			StopGainL3: 0,
			OrderQuantityL3: 0,
			UseAuctionOpenPrice: 'N',
		},
		StrategyLegs: [
			{
				LegSymbol: '',
				ILegAllocAccount: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
			},
		],
	},
	fxorderEditing: {
		StrategyCode: 'fxorder',
		CustomParameters: {
			OrderQuantityL1: '0',
			OrderQuantityL2: '0',
			OrderQuantityL3: '0',
			StartPriceTrigger: '0',
			StopGain: '0',
			StopGainL1: '0',
			StopGainL2: '0',
			StopGainL3: '0',
			StopLoss: '0',
		},
		StrategyLegs: [
			{
				LegRefID: '33f5cf2e-4739-46fb-8b30-bb21460e3ec8:1',
			},
		],
	},
	sniper: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'sniper',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
			},
		],
	},
	sniperEditing: {
		StrategyCode: 'sniper',
		CustomParameters: {
			PriceLimit: '',
		},
		StrategyLegs: [
			{
				LegQuantity: '',
			},
		],
	},
	mit: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'mit',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0,
			RestingPrice: 0,
			RestingQuantity: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegMinClipSize: 0,
			},
		],
	},
	mitEditing: {
		StrategyCode: 'mit',
		CustomParameters: {
			PriceLimit: 0,
			RestingPrice: 0,
			RestingQuantity: 0,
		},
		StrategyLegs: [
			{
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegMinClipSize: 0,
			},
		],
	},
	iceberg: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		TimeInForce: TIME_IN_FORCE,
		StrategyCode: 'iceberg',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0,
			FixedOpenQuantity: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	icebergEditing: {
		StrategyCode: 'iceberg',
		CustomParameters: {
			PriceLimit: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	boffer: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'boffer',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	bofferEditing: {
		StrategyCode: 'boffer',
		CustomParameters: {
			PriceLimit: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	twap: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		TimeInForce: TIME_IN_FORCE,
		Text: TEXT,
		BasketId: BASKET_ID,
		StrategyCode: 'twap',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0,
			AgressionLevel: 0,
			UsingFinancialVolume: 'N',
			MaxFinancialVolume: 0,
			LimitByVolume: 'N',
			TargetPercent: 0,
			ConsiderCrossOrders: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegOrdType: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	twapEditing: {
		StrategyCode: 'twap',
		CustomParameters: {
			AgressionLevel: '1',
			ConsiderCrossOrders: 'Y',
			MaxFinancialVolume: 0,
			PriceLimit: 0,
			TargetPercent: 0,
			CompleteToMarket: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	pov: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		TimeInForce: TIME_IN_FORCE,
		Text: TEXT,
		BasketId: BASKET_ID,
		StrategyCode: 'pov',
		Accounts: '',
		CustomParameters: {
			PriceLimit: 0.0,
			TargetPercent: 10,
			AgressionLevel: 0, // 0 ou 2
			ConsiderCrossOrders: 'Y',
			ConsiderCrossOrdersNotIntentional: 'Y',
			ConsiderPriceLimitInVolume: 'N',
			ParticipateOwnVolume: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegOrdType: 1,
				LegMaxClipSize: 0,
				LegFirstTimeOut: 10,
				LegSecondTimeOut: 10,
			},
		],
	},
	povEditing: {
		StrategyCode: 'pov',
		CustomParameters: {
			PriceLimit: 0.0,
			TargetPercent: 0.0,
			AgressionLevel: '2', // 0 ou 2
			ConsiderCrossOrders: 'N',
			ConsiderCrossOrdersNotIntentional: 'N',
			ConsiderPriceLimitInVolume: 'N',
			ParticipateOwnVolume: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegOrdType: 1,
				LegMaxClipSize: 0,
				LegFirstTimeOut: 10,
				LegSecondTimeOut: 10,
			},
		],
	},
	grdlin: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		TimeInForce: TIME_IN_FORCE,
		StrategyCode: 'grdlin',
		Accounts: '',
		CustomParameters: {
			OrderQuantity: 0,
			EntrancePrice: null,
			IncrementPriceStepEntrance: 0,
			NumberOfEntrances: 0,
			IncrementPriceStopGain: 0,
			NumberOfMinPriceIncrementsToStart: 0,
			UseStartWhenCross: 'N',
			StartWhenCross: 0,
			SelectStopByPrice1: 0,
			StopByPrice1: null,
			SelectStopByPrice2: 0,
			StopByPrice2: null,
			StopLossFinance: null,
			UseStopGainGlobalFinance: 'N',
			StopGainGlobalFinance: null,
			SendStopLossOnEnd: 'Y',
			UsePreservation: 'N',
			PreservationStartTrigger: null,
			PreservationPercentual: null,
			PreservationStep: 10,
			UseRetroaliment: 'N',
			MaximumZeroTimeWindow: null,
			RetroalimentType: 5,
			RetroalimentParam1: null,
			ZerarStopLoss: 'Y',
			RetroalimentTimeWindow: 120,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	grdlinEditing: {
		StrategyCode: 'grdlin',
		CustomParameters: {
			EntrancePrice: '5000',
			IncrementPriceStepEntrance: '20',
			IncrementPriceStopGain: '20',
			NumberOfEntrances: '10',
			OrderQuantity: '1',
			PreservationPercentual: null,
			PreservationStartTrigger: null,
			PreservationStep: '10',
			SendStopLossOnEnd: 'Y',
			StartWhenCross: '0',
			StopByPrice: '0',
			StopGainGlobalFinance: '100',
			StopLossFinance: null,
			UseStopByPrice: 'N',
			UseStopGainGlobalFinance: 'N',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegRefID: '5903ad8b-67b5-406c-9270-597c1e838120:1',
			},
		],
	},
	grddin: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'grddin',
		CustomParameters: {
			OrderQuantity: 0,
			EntrancePrice: null,
			IncrementPriceStepEntrance: 0,
			NumberOfEntrances: 0,
			IncrementPriceStopGain: 0,
			NumberOfMinPriceIncrementsToStart: 0,
			UseStartWhenCross: 'N',
			StartWhenCross: 0,
			SelectStopByPrice1: 0,
			StopByPrice1: null,
			SelectStopByPrice2: 0,
			StopByPrice2: null,
			StopLossFinance: null,
			UseStopGainGlobalFinance: 'N',
			StopGainGlobalFinance: null,
			SendStopLossOnEnd: 'Y',
			UsePreservation: 'N',
			PreservationStartTrigger: null,
			PreservationPercentual: null,
			PreservationStep: 10,
			UseRetroaliment: 'N',
			MaximumZeroTimeWindow: null,
			RetroalimentType: 5,
			RetroalimentParam1: null,
			ZerarStopLoss: 'Y',
			RetroalimentTimeWindow: 120,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	grddinEditing: {
		StrategyCode: 'grddin',
		CustomParameters: {
			EntrancePrice: 0,
			IncrementPriceStepEntrance: 0,
			IncrementPriceStopGain: 0,
			MaximumZeroTimeWindow: 120,
			NumberOfEntrances: 0,
			OrderQuantity: 0,
			PreservationPercentual: null,
			PreservationStartTrigger: null,
			PreservationStep: 10,
			RetroalimentParam1: null,
			RetroalimentType: 5,
			SendStopLossOnEnd: 'Y',
			StartWhenCross: 0,
			StopByPrice: 0,
			StopGainGlobalFinance: 0,
			StopLossFinance: null,
			UseStopByPrice: 'N',
		},
		StrategyLegs: [
			{
				LegRefID: '88945c9c-78e5-43e1-8e8d-8198816eb85d:1',
			},
		],
	},
	cvvol: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'cvvol',
		Accounts: '',
		CustomParameters: {
			BypassFirewall: 'N',
			TriggerValue: null,
			Trigger: 6,
			BookDepth: 3,
			ExecutionLimit: 1,
			DeltaFixedValue: 0,
			InterestRate: null,
			DeltaType: 1,
			InitSuspended: 'N',
			ExecStrategy: 2,
			DaysToExpiration: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'Y',
				LegOrdType: 2,
				LegFirstTimeOut: 5000,
				LegSecondTimeOut: 5000,
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'N',
				LegOrdType: 2,
				LegFirstTimeOut: 5000,
				LegSecondTimeOut: 5000,
			},
		],
	},
	skew: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		BasketId: BASKET_ID,
		TimeInForce: TIME_IN_FORCE,
		StrategyCode: 'skew',
		Accounts: '',
		CustomParameters: {
			TriggerValue: null,
			ExecutionLimit: 1,
			ExecutionLimitValue: 0,
			DeltaType: 1,
			DeltaFixedValue1: 0,
			DeltaFixedValue2: 0,
			ExecutionMode: 2,
			InterestRate: null,
			BookDepth: 3,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'Y',
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
				LegResting: 'Y',
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	marketmaker: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'marketmaker',
		CustomParameters: {
			TriggerType: 1,
			TriggerValue: 0,
			AgressorThresholdOn: 0.9,
			AgressorThresholdOff: 0.8,
			StopLossFinance: -0.01,
			StopPositionQuantity: 0,
			StopLossPerc: 0.3,
			StopGainPerc: 0.2,
			RevertOnExecQty: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	marketmakerEditing: {
		StrategyCode: '',
		CustomParameters: {
			TriggerType: 1,
			TriggerValue: 0,
			AgressorThresholdOn: 0,
			AgressorThresholdOff: 0,
			StopLossFinance: 0,
			StopPositionQuantity: 0,
			StopLossPerc: 0,
			StopGainPerc: 0,
			RevertOnExecQty: 0,
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '2',
				LegQuantity: 0,
				LegMaxClipSize: 0,
			},
		],
	},
	portbalance: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		BasketId: BASKET_ID,
		StrategyCode: 'portbalance',
		CustomParameters: {
			SymbolList: '',
			InitialPosList: '',
			DesiredPosPercList: '',
			TriggerValue: 0,
			UseFractionary: 'Y',
			SecurityExchange: 'XBSP',
			IgnoreOffersLT: 0,
			AllocAccount: '',
		},
		StrategyLegs: [],
	},
	portbalanceEditing: {
		StrategyCode: 'portbalance',
		CustomParameters: {
			SymbolList: '',
			InitialPosList: '',
			DesiredPosPercList: '',
			TriggerValue: 0,
			UseFractionary: 'N',
			IgnoreOffersLT: 0,
		},
		StrategyLegs: [],
	},
	gap: {
		Name: '',
		InitTime: INIT_TIME,
		EndTime: END_TIME,
		ExpireDate: EXPIRE_DATE,
		Text: TEXT,
		isRecurrence: true,
		TimeInForce: TIME_IN_FORCE,
		BasketId: BASKET_ID,
		StrategyCode: 'gap',
		CustomParameters: {
			FixedPrice: null,
			TargetPrice: 'R',
			RelativeType: 'O',
			RelativeDirection: 'U',
			RelativeUnit: 'P',
			RelativePxRel: null,
			PxInitTimeMonitoring: null,
			PxEndTimeMonitoring: null,
			EntranceMoment: 'I',
			EntranceUnit: null,
			PxRelSlippagePts: null,
			EntrancePxRelNext: null,
			MaxOpenGapPrice: null,
			ExitUnit: 'I',
			ExitPxSlippagePts: null,
			ExitPxRelGain: null,
			ExitPxRelLoss: null,
			ExitOnEndTime: 'Y',
			ExitOnEndDayAuction: 'N',
			_useApparentQuantity: false,
			_relativePxInitTimeOption: 'specified',
		},
		StrategyLegs: [
			{
				ILegAllocAccount: '',
				LegSymbol: '',
				LegSecurityExchange: LEG_SECURITY_EXCHANGE,
				LegSide: '1',
				LegQuantity: 0,
				LegMinClipSize: 0,
				LegTimeInForce: TIME_IN_FORCE,
			},
		],
	},
};

export const getStrategyTemplate = (strategyCode, isEditing = false) => {
	const strategyCodeToReturn = isEditing
		? `${strategyCode}Editing`
		: strategyCode;

	if (
		Object.prototype.hasOwnProperty.call(strategies, strategyCodeToReturn)
	) {
		const objToReturn = strategies[strategyCodeToReturn];
		return {
			...objToReturn,
			CustomParameters: { ...objToReturn.CustomParameters },
			StrategyLegs: objToReturn.StrategyLegs.map(leg => {
				return { ...leg };
			}),
		};
	}

	return {};
};
