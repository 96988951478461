import { store } from 'store';

export function formatPositions(positions) {
	const formattedPositions = positions.map(position => ({
		...position,
		id: `${position.symbol}-${position.account}`,
	}));

	if (formattedPositions.length === 0) {
		return formattedPositions;
	}

	const hasCurrentSortingModel =
		store.getState().consolidatedPositions.tablePreferences.sorting
			.sortModel.length > 0;

	if (hasCurrentSortingModel) {
		return formattedPositions;
	}

	return formattedPositions.sort((a, b) => a.symbol.localeCompare(b.symbol));
}

function calcMediumPrice(key, currentPosition, positions) {
	const selectedQuantity = key === 'avgSellPx' ? 'sellQtty' : 'buyQtty';

	const totalQuantity = positions.reduce((total, position) => {
		return total + position[selectedQuantity];
	}, 0);

	const result =
		(currentPosition[key] * currentPosition[selectedQuantity]) /
		totalQuantity;

	return result;
}

function sumPositions(positions) {
    const filters = store.getState().positions.tablePreferences.filter.filterModel.items;

    return positions.reduce((sum, position) => {
        const matchesFilters = filters.every(filter => {
            const { columnField, operatorValue, value } = filter;
            const fieldValue = position[columnField];			

            switch (operatorValue) {
                case 'equals':
                    return fieldValue === value;
                case 'contains':
                    return String(fieldValue).includes(value);
                case 'isEmpty':
                    return fieldValue === '';
				case 'isNotEmpty':
					return fieldValue !== '';
                case 'isAnyOf':
                    return value.includes(fieldValue);
				case 'startsWith':
					return String(fieldValue).startsWith(value);
				case 'endsWith':
					return String(fieldValue).endsWith(value);
                default:
                    return true;
            }
        });

        if (!matchesFilters) {
            return sum;
        }

        for (const key in position) {
            if (key !== 'id' && typeof position[key] === 'number') {
                sum[key] =
                    (sum[key] || 0) +
                    (['avgSellPx', 'avgBuyPx'].includes(key)
                        ? calcMediumPrice(key, position, positions)
                        : position[key]);
            }
        }

        return sum;
    }, {});
}

function getFormattedNumber(number, field) {
	const digitMap = {
		buyQtty: 0,
		sellQtty: 0,
		avgBuyPx: 5,
		avgSellPx: 5,
	};

	const digits = Object.prototype.hasOwnProperty.call(digitMap, field)
		? digitMap[field]
		: 2;

	if (number === null || isNaN(number)) {
		return 0;
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	}).format(number);
}

export function getPositionsConsolidation(positions) {
	const summedPositions = sumPositions(positions);

	const formattedPositions = {};

	for (const key in summedPositions) {
		formattedPositions[key] = getFormattedNumber(summedPositions[key], key);
	}

	return formattedPositions;
}
