import React from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ButtonGroup,
	colors,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';

import paperValidator from 'utils/paperValidation';
import { DescriptionTooltip, LightHTMLTooltip } from 'components/HelperTooltip';
import { updateCustomParameters } from 'store/modules/bottomBullet/actions';
import { NumericInput } from 'components/Bullet/NumericInput';

export const GapExitParams = () => {
	const dispatch = useDispatch();

	const bullet = useSelector(state => state.bottomBullet.bullet);
	const accounts = useSelector(state => state.auth.accounts);
	const defaultAccount = useSelector(
		state =>
			state.configs.globalSettings?.userSettings?.bullet.defaultAccount
	);

	const { content, StrategyInfos } = bullet;

	const {
		ExitUnit,
		ExitOnEndDayAuction,
		ExitOnEndTime,
		ExitPxRelGain,
		ExitPxRelLoss,
	} = content.CustomParameters;

	const { minPriceIncrement, tickSizeDenominator, asset } = StrategyInfos[0];

	const notSelectedButtonStyle = {
		color: colors.grey[500],
		borderColor: colors.grey[600],
	};

	const isBulletDisabled = paperValidator(StrategyInfos[0], bullet);
	const isBulletDisabledBySingleSideButton = paperValidator(
		StrategyInfos[0],
		bullet,
		'singleSideButtom'
	);

	const exitOnSelection = ExitOnEndTime === 'Y' ? 'endTime' : 'endDayAuction';

	function handleToggleExitOnSelection() {
		dispatch(
			updateCustomParameters({
				ExitOnEndTime: ExitOnEndTime === 'Y' ? 'N' : 'Y',
				ExitOnEndDayAuction: ExitOnEndDayAuction === 'Y' ? 'N' : 'Y',
			})
		);
	}

	function handleToggleExitUnit() {
		dispatch(
			updateCustomParameters({
				ExitUnit: ExitUnit === 'I' ? 'P' : 'I',
				ExitPxRelGain: '',
				ExitPxRelLoss: '',
			})
		);
	}

	function handleUpdateCustomParameters(field, value) {
		dispatch(
			updateCustomParameters({
				[field]: value,
			})
		);
	}

	function getCurrentAccount(bulletAccount, authAccounts, defaultAccount) {
		if (bulletAccount) {
			return bulletAccount;
		}

		if (authAccounts.length > 0) {
			if (![null, undefined, '', 'Nenhuma'].includes(defaultAccount)) {
				return defaultAccount;
			}

			return authAccounts[0].code;
		}

		return;
	}

	const currentAccount = getCurrentAccount(
		bullet.account,
		accounts,
		defaultAccount
	);

	function hasExitOnEndDayAuctionToBeDisabled(currentAccount, asset) {
		if (asset && asset === 'WDO') {
			return true;
		}

		if (currentAccount) {
			if (String(currentAccount).startsWith('D-')) {
				return true;
			}

			return false;
		}

		return true;
	}

	const isExitOnEndDayAuctionDisabled = hasExitOnEndDayAuctionToBeDisabled(
		currentAccount,
		asset
	);

	return (
		<Grid
			item
			container
			direction="column"
			sx={{
				bgcolor: colors.grey[900],
				borderRadius: 1,
				p: 1,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: 1,
				}}
			>
				<Typography variant="subtitle1">Parâmetros de Saída</Typography>

				<LightHTMLTooltip
					element={<Info fontSize="small" sx={{ mt: 0.25 }} />}
				>
					<DescriptionTooltip
						label="Início de range"
						description={t('bullets.gradient.entrance_price')}
					/>
				</LightHTMLTooltip>
			</Box>

			<Grid item container direction="column" spacing={1}>
				<Grid
					item
					xs
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						mt: 1,
					}}
				>
					<ButtonGroup
						fullWidth
						size="small"
						disabled={isBulletDisabledBySingleSideButton}
						sx={{
							mb: 0.25,
							height: 25,
							cursor: isBulletDisabledBySingleSideButton
								? 'not-allowed'
								: 'auto',
						}}
						onClick={handleToggleExitUnit}
					>
						<Button
							color={ExitUnit === 'I' ? 'primary' : 'inherit'}
							variant={
								ExitUnit === 'I' ? 'contained' : 'outlined'
							}
							sx={ExitUnit === 'P' ? notSelectedButtonStyle : {}}
						>
							Pontos
						</Button>

						<Button
							color={ExitUnit === 'P' ? 'primary' : 'inherit'}
							variant={
								ExitUnit === 'P' ? 'contained' : 'outlined'
							}
							sx={ExitUnit === 'I' ? notSelectedButtonStyle : {}}
						>
							%
						</Button>
					</ButtonGroup>
				</Grid>

				<Grid item container gap={2}>
					<Grid item xs>
						<NumericInput
							id="ExitPxRelGain"
							label={`Gain (${
								ExitUnit === 'I' ? 'pontos' : '%'
							})`}
							suffix={ExitUnit === 'I' ? '' : '%'}
							value={ExitPxRelGain ?? ''}
							onChangeCallback={value => {
								handleUpdateCustomParameters(
									'ExitPxRelGain',
									value
								);
							}}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled}
							fixedDecimalScale
						/>
					</Grid>

					<Grid item xs>
						<NumericInput
							value={ExitPxRelLoss ?? ''}
							onChangeCallback={value =>
								handleUpdateCustomParameters(
									'ExitPxRelLoss',
									value
								)
							}
							roundOnBlur
							decimalScale={
								ExitUnit === 'I' ? tickSizeDenominator : 2
							}
							stepSize={
								ExitUnit === 'P' ? 0.01 : minPriceIncrement
							}
							minValue={minPriceIncrement}
							disabled={isBulletDisabled}
							fixedDecimalScale
							label={`Loss (${
								ExitUnit === 'I' ? 'pontos' : '%'
							})`}
							suffix={ExitUnit === 'I' ? '' : '%'}
						/>
					</Grid>
				</Grid>

				<Grid item container spacing={1}>
					<Grid item xs sx={{ mt: 1 }}>
						<FormControl disabled={isBulletDisabled}>
							<FormLabel>Termina em:</FormLabel>
							<RadioGroup
								row
								value={exitOnSelection}
								sx={{ flexWrap: 'nowrap' }}
								onChange={handleToggleExitOnSelection}
							>
								<FormControlLabel
									value="ExitOnEndTime"
									control={<Radio />}
									label="Hora fim"
									checked={exitOnSelection === 'endTime'}
								/>

								<FormControlLabel
									value="ExitOnEndDayAuction"
									control={<Radio />}
									label="Leilão de fechamento"
									checked={
										exitOnSelection === 'endDayAuction'
									}
									disabled={
										isBulletDisabled ||
										isExitOnEndDayAuctionDisabled
									}
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
