import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography, colors } from '@mui/material';

import { setModalPreference } from 'store/modules/userPreferences/actions';
import { postNotification } from 'services/userPreferences/notifications';

import { ModalTemplate } from '../../AppHeader/Modals/Template';
import MaterialCheckBox from 'components/MaterialComponents/MaterialCheckBox';
import { Container, SubmitBox } from './styles';
import { PRODUCT_TYPES } from 'utils/constants';

export const NewGradientModule = () => {
	const dispatch = useDispatch();

	const { username, token } = useSelector(state => state.auth);

	const { activeProduct } = useSelector(state => state.products);

	const [checked, setChecked] = useState(false);

	const [isOpen, setIsOpen] = useState(true);

	function toggleCheckbox(e) {
		setChecked(e.target.checked);
		dispatch(setModalPreference('newGradientModule', !e.target.checked));
		postNotification(username, token);
	}

	return (
		<ModalTemplate
			onClose={() => setIsOpen(false)}
			open={isOpen}
			title="Mensagem Promocional: Novo Módulo Disponível!"
		>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						gap: 1,
						display: 'flex',
						flexDirection: 'column',
						borderRadius: 2,
					}}
				>
					{activeProduct.code === PRODUCT_TYPES.FLEX_STANDARD ? (
						<>
							<Typography
								variant="h6"
								style={{ marginBottom: '5px' }}
							>
								Gradiente Linear no FlexScan! Vamos nessa?
							</Typography>
							<Typography
								variant="h6"
								style={{ marginBottom: '5px' }}
							>
								Preparado para operar o Gradiente Linear em
								outro nível ?
							</Typography>
							<Typography
								variant="body1"
								style={{
									color: 'lightgrey',
									marginBottom: '5px',
								}}
							>
								Informamos que a Investflex acabou de lançar o
								Super GL, um novo módulo no FlexScan que reúne
								todos os insumos necessários para que você
								planeje e execute suas estratégias gradientes.
							</Typography>

							<Typography variant="h7">
								Algumas funcionalidades que você encontrará no
								módulo:
							</Typography>
							<Typography
								variant="body1"
								style={{
									color: 'lightgrey',
									marginBottom: '5px',
								}}
							>
								<ul style={{ margin: 0, paddingLeft: '20px' }}>
									<li>
										Acompanhamento gráfico das ordens de
										gradiente de mini contratos de índice e
										dólar
									</li>
									<li>
										Stop Gain e Loss Global para todos as
										estratégias de Gradiente que estiverem
										rodando simultaneamente
									</li>
									<li>
										Novas boletas de Gradientes de
										Esgotamento e Gradiente a Mercado
									</li>
								</ul>
							</Typography>

							<Typography
								variant="h7"
								style={{ marginBottom: '5px' }}
							>
								Disponível nos planos Advanced e Professional, o
								Super GL pode ajudar você a alcançar um novo
								patamar em seu trading.{' '}
							</Typography>
						</>
					) : (
						<>
							<Typography
								variant="h6"
								style={{ marginBottom: '5px' }}
							>
								Novidade: Módulo Super Gradiente Linear
								disponível para você
							</Typography>

							<Typography
								variant="h7"
								style={{ marginBottom: '5px' }}
							>
								É com grande satisfação que informamos que um
								novo módulo está disponível que vai lhe permitir
								executar o Gradiente Linear em outro patamar.
							</Typography>

							<Typography variant="h7">
								Algumas funcionalidades que você encontrará no
								módulo:
							</Typography>
							<Typography
								variant="body1"
								style={{
									color: 'lightgrey',
									marginBottom: '5px',
								}}
							>
								<ul style={{ margin: 0, paddingLeft: '20px' }}>
									<li>
										Acompanhamento gráfico das ordens de
										gradiente de mini contratos de índice e
										dólar.
									</li>
									<li>
										Stop Gain e Loss Global para todas as
										estratégias de Gradiente que estiverem
										rodando simultaneamente.
									</li>
									<li>
										Novas boletas de Gradientes de
										Esgotamento e Gradiente a Mercado.
									</li>
								</ul>
							</Typography>
							<Typography
								variant="h7"
								style={{ marginBottom: '5px' }}
							>
								<span
									style={{
										color: 'orange',
										fontWeight: 'bold',
									}}
								>
									Importante:
								</span>{' '}
								Com a incorporação do módulo Super GL no
								FlexScan também ficará habilitado o Stop Global
								- que valerá mesmo que você utilize a boleta
								antiga.
							</Typography>
						</>
					)}
				</Box>
				<a
					href={
						'https://investflex.freshdesk.com/a/solutions/articles/61000314257'
					}
					target="_blank"
					rel="noopener noreferrer"
					style={{ color: colors.lightBlue[500] }}
				>
					Consulte a documentação para saber mais sobre o Super GL.
				</a>

				<SubmitBox>
					<MaterialCheckBox
						checked={checked}
						onChange={toggleCheckbox}
						label="Não exibir novamente"
					/>

					<Button
						variant="contained"
						onClick={() => setIsOpen(false)}
					>
						Entendi
					</Button>
				</SubmitBox>
			</Container>
		</ModalTemplate>
	);
};
