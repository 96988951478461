import React, { useState } from 'react';

import { Avatar } from '@mui/material';

export const MarketPriceLogo = ({ asset }) => {
	const [isError, setIsError] = useState(false);

	function getFallbackLabel() {
		return asset ? String(asset).charAt(0).toUpperCase() : ' ';
	}

	function getImageSource() {
		if (asset) {
			const formattedAsset = String(asset).toUpperCase();
			return `https://instr-logo.flexscan.com.br/${formattedAsset}.svg`;
		}

		return null;
	}

	const fallbackLabel = getFallbackLabel();
	const imageSource = getImageSource();

	if (isError && imageSource) {
		setIsError(false);
	}

	return (
		<Avatar
			sx={{ width: 26, height: 26, borderRadius: 1 }}
			variant="square"
			{...(!isError && { src: imageSource })}
			onError={() => setIsError(true)}
		>
			{fallbackLabel}
		</Avatar>
	);
};
