import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getResponseAction } from 'utils/partnerProducts';

export const PartnerProduct = () => {
	const history = useHistory();
	const params = useParams();

	const iframeRef = useRef(null);

	const { token, username, email } = useSelector(state => state.auth);

	const activeProduct = useSelector(state => state.products.activeProduct);

	const urlIframe = activeProduct.code === 'TRADE_CERTO' ? params[0] : '';

	const originHost = window.location.origin;

	const urlPartnerProduct = activeProduct.urlPartnerProduct;
	// const urlPartnerProduct = 'http://localhost:3000';

	async function handleMessage(event) {
		if (event.origin !== urlPartnerProduct) {
			return;
		}

		// Caso nao tenha permissão pra acessar o iframe
		if (event.data.action && event.data.action === 'redirect') {
			toast.error('Usuário não tem permissão para acessar esse recurso!');
			history.push('/dashboard');
		}

		const { requestId, authToken, type, parameters } = event.data;

		if (authToken !== token) {
			return;
		}

		const isAllowedAction = activeProduct.attributes.actions.some(
			item => item === type
		);

		if (!isAllowedAction) {
			const messageNotAllowed = {
				requestId,
				authToken,
				type,
				parameters,
				statusCode: 403,
				response: {
					message: 'Sem permissao para acessar o recurso!',
					code: 4432,
				},
			};

			iframeRef.current.contentWindow.postMessage(
				messageNotAllowed,
				urlPartnerProduct
			);

			return;
		}

		const dataMessage = {
			requestId,
			authToken,
			type,
			parameters,
			productCode: activeProduct.code,
		};

		const responseAction = await getResponseAction(
			dataMessage,
			activeProduct
		);

		iframeRef.current.contentWindow.postMessage(
			responseAction,
			urlPartnerProduct
		);
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	return (
		<>
			<iframe
				ref={iframeRef}
				title={activeProduct.name}
				src={`${urlPartnerProduct}/${urlIframe}?authToken=${token}&userName=${username}&email=${email}&originHost=${originHost}`}
				style={{ border: 0, width: '100%', height: '100%' }}
			/>
		</>
	);
};
