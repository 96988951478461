import React, { useState } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	colors,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	OutlinedInput,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { apiExec } from 'services/api';
import { updateGradientStop } from 'store/modules/idxLiveCharts/actions';
import { DialogActions } from '@material-ui/core';

const GradientConfirmModal = ({
	isOpen,
	handleSubmit,
	isLoading,
	handleCancel,
}) => {
	return (
		<Dialog open={isOpen}>
			<DialogTitle>Atenção!</DialogTitle>

			<DialogContent>
				<DialogContentText>
					O novo valor de stop loss é maior que o valor anterior, isto
					pode ocasionar o cancelamento das estratégias executando
					caso o novo loss ingressado já ter sido atingido.
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button color="error" onClick={handleCancel}>
					Cancelar
				</Button>

				<LoadingButton loading={isLoading} onClick={handleSubmit}>
					Continuar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export const GradientSettings = () => {
	const dispatch = useDispatch();

	const gradientStopReduxValues = useSelector(
		state => state.idxLiveCharts.gradientStop
	);

	const globalStopInitialValues = {
		finLimitLoss: Number(gradientStopReduxValues.finLimitLoss),
		finLimitGain: Number(gradientStopReduxValues.finLimitGain),
	};

	const [isGlobalStopIdModalOpen, setIsGlobalStopIdModalOpen] =
		useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [globalStopValues, setGlobalStopValues] = useState(
		globalStopInitialValues
	);

	function handleChangeNumericValues(field, value) {
		setGlobalStopValues({ ...globalStopValues, [field]: Number(value) });
	}

	function handleCloseGlobalStopIdModal() {
		setIsGlobalStopIdModalOpen(false);
		setGlobalStopValues(globalStopInitialValues);
	}

	async function updateGlobalStops(globalStopId) {
		globalStopId = null;
		setIsLoading(true);
		let stopId = globalStopId;
		if ([null, undefined].includes(stopId)) {
			const queryParams = {
				params: {
					realtime: true,
				},
			};
			await apiExec
				.get('/globalstops', queryParams)
				.then(response => {
					stopId = response.data.records[0].id;
				})
				.catch(error => console.error(error));
		}

		apiExec
			.put(`/globalstops/${stopId}`, globalStopValues)
			.then(() => {
				dispatch(
					updateGradientStop({
						...gradientStopReduxValues,
						...globalStopValues,
					})
				);
				toast.success('Stop global atualizado com sucesso!');
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => setIsLoading(false));
	}

	function validateNeedWarning() {
		if (
			globalStopValues.finLimitLoss > gradientStopReduxValues.finLimitLoss
		) {
			return true;
		}

		return false;
	}

	function onSubmit(globalStopId) {
		if (
			isNaN(Number(globalStopValues.finLimitGain)) ||
			isNaN(Number(globalStopValues.finLimitGain))
		) {
			alert('Preencha os campos corretamente.');
			return;
		}

		const isWarnNeeded = validateNeedWarning();

		if (isWarnNeeded) {
			setIsGlobalStopIdModalOpen(true);
			return;
		}

		updateGlobalStops(globalStopId);
	}

	function handleSubmit() {
		updateGlobalStops(gradientStopReduxValues.id);
		setIsGlobalStopIdModalOpen(false);
	}

	const isSubmitDisabled =
		!globalStopValues.finLimitLoss || !globalStopValues.finLimitGain;

	const stopLossColor =
		globalStopValues.finLimitLoss < 0 ? colors.red[300] : colors.green[400];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 8,
				}}
			>
				<Typography variant="h6">Stop Global Gradientes</Typography>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'white',
						padding: '0.25rem 0.5rem',
						borderRadius: '5px',
						fontSize: '0.8rem',
						height: '18px',
						background: colors.red[700],
					}}
				>
					BETA
				</div>
			</div>

			<Typography variant="subtitle1">
				O stop loss limita as perdas encerrando automaticamente uma
				negociação quando o preço cai a um certo nível. Já o stop gain
				garante lucros ao fechar a posição quando o preço atinge o valor
				desejado.
			</Typography>
			<Typography variant="subtitle1">
				<span style={{ color: 'orange' }}>Importante: </span>StopLoss
				para perdas, informar valores negativos. StopLoss para
				preservação de lucro, informar valores positivos. StopGain
				sempre valores positivos.
			</Typography>

			<Grid gap={1} xs={12} container item flexWrap="nowrap">
				<Grid
					item
					xs={6}
					sx={{
						flexWrap: 'nowrap',
						display: 'flex',
						alignItems: 'flex-end',
					}}
				>
					<FormControl fullWidth>
						<FormLabel>Stop Loss (R$)</FormLabel>
						<NumberFormat
							customInput={OutlinedInput}
							className="hide-input-spinner"
							size="small"
							name="finLimitLoss"
							value={globalStopValues.finLimitLoss}
							defaultValue={gradientStopReduxValues.finLimitLoss}
							onValueChange={values => {
								const { floatValue } = values;
								handleChangeNumericValues(
									'finLimitLoss',
									floatValue
								);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale
							sx={{
								color: stopLossColor,
								'& .MuiOutlinedInput-input': {
									color: stopLossColor,
								},
							}}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={6}>
					<FormControl fullWidth>
						<FormLabel>Stop Gain (R$)</FormLabel>
						<NumberFormat
							customInput={OutlinedInput}
							className="hide-input-spinner"
							size="small"
							name="finLimitGain"
							value={globalStopValues.finLimitGain}
							defaultValue={gradientStopReduxValues.finLimitGain}
							onValueChange={values => {
								const { floatValue } = values;
								handleChangeNumericValues(
									'finLimitGain',
									floatValue
								);
							}}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							allowNegative={false}
							fixedDecimalScale
							sx={{
								'& .MuiOutlinedInput-input': {
									color: colors.green[400],
								},
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>

			<div>
				<Button
					variant="contained"
					size="small"
					onClick={() => onSubmit(gradientStopReduxValues.id)}
					disabled={isSubmitDisabled}
				>
					Salvar
				</Button>
			</div>

			<GradientConfirmModal
				isLoading={isLoading}
				isOpen={isGlobalStopIdModalOpen}
				handleCancel={handleCloseGlobalStopIdModal}
				handleSubmit={handleSubmit}
			/>

			<Divider orientation="horizontal" />
		</Box>
	);
};
