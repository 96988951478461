import React, { useRef, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Divider,
	Grid,
	TextField,
	Typography,
	colors,
} from '@mui/material';
import { EmailOutlined, PhoneAndroid } from '@mui/icons-material';

import { apiAWS } from 'services/api';
import { sendValidationCode } from 'services/sendValidationCode';

import { CountdownTimer } from './CountdownTimer';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

const AUTHENTICATION_ERROR_MESSAGES = {
	7779: 'Código expirado.',
	7778: 'Dados informados incorretos.',
	7777: 'Número máximo de tentativas atingido. Favor gerar um novo código de acesso.',
	7000: 'Não autorizado',
};

export const EmailAuthentication = ({
	props: {
		setLoginStep,
		credentials,
		mfaCodeInfo,
		handleCancel,
		setMfaCodeInfo,
		isTimerFinished,
		setIsTimerFinished,
		setAuthMethod,
		authToken,
	},
}) => {
	const codeRefs = Array.from({ length: 6 }, () => useRef());

	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	useEffect(() => {
		// Foca no primeiro campo de input quando o componente é montado
		codeRefs[0].current.focus();

		function handleInput(index, event) {
			const previousIndex = index > 0 ? index - 1 : null;
			const nextIndex = index < codeRefs.length - 1 ? index + 1 : null;
			const maxLength = parseInt(event.target.getAttribute('maxlength'));

			if (
				event.inputType === 'deleteContentBackward' &&
				event.target.value.length === 0
			) {
				// Se a tecla "Backspace" foi pressionada e o campo está vazio, move para o campo anterior, se houver
				if (previousIndex !== null) {
					codeRefs[previousIndex].current.focus();
				}
				return;
			}

			if (event.target.value.length >= maxLength) {
				// Move para o próximo campo de entrada se o valor atual atingir o comprimento máximo
				if (nextIndex !== null) {
					codeRefs[nextIndex].current.focus();
				}
			}
		}

		function handlePaste(e) {
			const clipboardData = e.clipboardData || window.Clipboard;
			const pastedText = clipboardData.getData('Text');

			// Divide o texto colado em caracteres
			const pastedChars = pastedText.split('');

			// Preenche os campos subsequentes
			pastedChars.forEach((char, index) => {
				if (codeRefs[index]) {
					codeRefs[index].current.value = char.toUpperCase();
				}
			});

			codeRefs[codeRefs.length - 1].current.focus();
		}

		// Adiciona manipuladores de eventos aos campos de entrada
		codeRefs.forEach((ref, index) => {
			ref.current.addEventListener('input', e => handleInput(index, e));
			ref.current.addEventListener('paste', e => handlePaste(e));
		});

		return () => {
			// Remove os manipuladores de eventos ao desmontar o componente
			codeRefs.forEach(ref => {
				ref.current?.removeEventListener('input', handleInput);
				ref.current?.removeEventListener('paste', handlePaste);
			});
		};
	}, []);

	function handleSubmit() {
		const formattedCode = codeRefs
			.map(ref => ref.current.value)
			.join('')
			.toUpperCase();

		if (
			formattedCode &&
			formattedCode !== '' &&
			formattedCode.length === 6
		) {
			setIsLoading(true);

			const headers = {
				headers: { 'x-authorization': mfaCodeInfo.transactional_token },
			};

			apiAWS
				.get(`/credentials/tokens/${formattedCode}`, headers)
				.then(() => setLoginStep('contractValidation'))
				.catch(error => {
					console.error(error);

					if (error.response?.data?.code === 7777) {
						setMfaCodeInfo({});
						setIsTimerFinished(true);
					}

					const errorMessage =
						AUTHENTICATION_ERROR_MESSAGES[
							error.response?.data?.code
						];

					setSnackbarState(state => ({
						...state,
						isOpen: true,
						message:
							errorMessage ||
							error.response?.data?.title ||
							error.message,
						severity: 'error',
					}));
				})
				.finally(() => setIsLoading(false));
		} else {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: 'Preencha o campo do código corretamente.',
			}));
		}
	}

	function onTimerEnd() {
		setIsTimerFinished(true);
	}

	async function handleSendNewCode() {
		try {
			const codeInfos = await sendValidationCode(
				credentials.user,
				authToken
			);

			if (!codeInfos) {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message:
						'Erro ao enviar código de verificação! Tente novamente!',
					severity: 'error',
				}));

				return;
			}

			setMfaCodeInfo(codeInfos);
			setIsTimerFinished(false);
		} catch (error) {
			console.error(error);

			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message: error.message,
				severity: 'error',
			}));
		}
	}

	return (
		<>
			<Typography
				variant="h5"
				sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
			>
				<EmailOutlined />
				Validação por e-mail
			</Typography>

			<Box>
				<Typography
					variant="subtitle1"
					sx={{ color: colors.grey[800] }}
				>
					Um código de autenticação foi enviado para o seu e-mail:
				</Typography>

				<Typography variant="subtitle1" fontWeight="bold">
					{mfaCodeInfo.masked_email}
				</Typography>
			</Box>

			<Typography variant="subtitle1" sx={{ color: colors.grey[800] }}>
				Informe o código no campo abaixo:
			</Typography>

			<Grid container spacing={1}>
				{codeRefs.map((ref, index) => (
					<Grid key={index} item xs>
						<TextField
							inputRef={ref}
							inputProps={{
								maxLength: 1,
								style: {
									textAlign: 'center',
									textTransform: 'uppercase',
								},
							}}
						/>
					</Grid>
				))}
			</Grid>

			<Grid item container flexDirection="column" gap={2}>
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					loading={isLoading}
					disabled={!mfaCodeInfo.transactional_token}
				>
					Validar código
				</LoadingButton>

				<Button
					variant="outlined"
					disabled={!isTimerFinished}
					onClick={handleSendNewCode}
				>
					Reenviar código {!isTimerFinished && ' -'}
					{!isTimerFinished && (
						<CountdownTimer
							onTimerEnd={onTimerEnd}
							style={{ marginLeft: 4 }}
							isTimerFinished={isTimerFinished}
							timerDuration={
								process.env.REACT_APP_AUTH_CODE_TIMEOUT
							}
						/>
					)}
				</Button>
			</Grid>

			<Divider orientation="horizontal">
				<Typography
					variant="subtitle1"
					sx={{ color: colors.grey[500] }}
				>
					ou
				</Typography>
			</Divider>

			<Button
				variant="outlined"
				onClick={() => setAuthMethod('app')}
				color="inherit"
				startIcon={<PhoneAndroid />}
				sx={{ opacity: 0.5, '&:hover': { opacity: 0.7 } }}
			>
				Validação por aplicativo
			</Button>

			<Button onClick={handleCancel} color="error">
				Cancelar
			</Button>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'error'}
				handleClose={() =>
					setSnackbarState(state => ({
						...state,
						isOpen: false,
					}))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
