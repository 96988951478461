import React from 'react';
import { format, isValid, parseISO } from 'date-fns';

import { getFormattedDate } from 'utils/getParsedDate';

import { ChipCell } from 'components/ChipCell';
import { CopyCell } from 'components/CopyCell';

import { statusMapping } from 'utils/statusOrder';

function getFormattedNumber(number, minDigits = null, isZeroValid = false) {
	if (!number && (number !== 0 || !isZeroValid || isNaN(number))) {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: minDigits,
	}).format(number);
}

function getPercentage(value, strategyCode) {
	const isValidValue = ![null, undefined, '', '--'].includes(value);
	const isExcludedStrategy = ['grdlin', 'grddin', 'fxorder'].includes(
		strategyCode
	);

	if (!isValidValue || isExcludedStrategy) {
		return null;
	}

	const floatValue = parseFloat(value || 0);

	if ([100, 1].includes(floatValue)) {
		return 100;
	}

	if (floatValue < 1) {
		return (floatValue * 100).toFixed(2);
	}

	return floatValue.toFixed(2);
}

function getPercentageLabel(value, strategyCode) {
	const calculatedValue = getPercentage(value, strategyCode);

	return calculatedValue ? `${calculatedValue}%` : '';
}

const statusFilterValues = [
	{ value: 0, label: 'Nova' },
	{ value: 1, label: 'Pendente' },
	{ value: 2, label: 'Agendada' },
	{ value: 3, label: 'Executando' },
	{ value: 4, label: 'Desbalanceada' },
	{ value: 5, label: 'Finalizada' },
	{ value: 6, label: 'Cancelando' },
	{ value: 7, label: 'Cancelada' },
	{ value: 8, label: 'Suspendendo' },
	{ value: 9, label: 'Suspensa' },
	{ value: 10, label: 'Iniciando' },
	{ value: 99, label: 'Pendente' },
];

const timeInForceFilterValues = [
	{ value: '0', label: 'DIA' },
	{ value: '1', label: 'VAC' },
	{ value: '6', label: 'DES' },
];

const timeInForceValues = {
	0: { color: '#3FD293', label: 'DIA' },
	1: { color: '#c54fff', label: 'VAC' },
	6: { color: '#4891F2', label: 'DES' },
};

export const currentStrategiesColumns = [
	{
		flex: 0.75,
		field: 'Accounts',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Name',
		headerName: 'Estratégia',
		type: 'string',
	},
	{
		flex: 1,
		field: 'Text',
		headerName: 'Detalhe',
		type: 'string',
	},
	{
		flex: 0.8,
		field: 'CumQtyPerc',
		headerName: 'Porcent. Exec.',
		type: 'number',
		valueGetter: ({ value, row }) => {
			if (
				row.hierarchy.length === 1 &&
				!['grdlin', 'grddin'].includes(row.StrategyCode)
			) {
				return getPercentage(value, row.StrategyCode);
			}

			if (
				row.parentStrategy &&
				['grdlin', 'grddin'].includes(row.parentStrategy.StrategyCode)
			) {
				return '';
			}

			return getPercentage(value, row.StrategyCode);
		},
		renderCell: ({ value, row }) => {
			if (
				row.hierarchy.length === 1 &&
				!['grdlin', 'grddin'].includes(row.StrategyCode)
			) {
				return getPercentageLabel(value, row.StrategyCode);
			}

			if (
				row.parentStrategy &&
				['grdlin', 'grddin'].includes(row.parentStrategy.StrategyCode)
			) {
				return '';
			}

			return getPercentageLabel(value, row.StrategyCode);
		},
	},
	{
		flex: 0.8,
		field: 'TargetQtty',
		headerName: 'Qtd. Total',
		type: 'number',
		renderCell: ({ value, row }) => {
			if (row.hierarchy.length === 1) {
				if (['grdlin', 'grddin'].includes(row.StrategyCode)) {
					return '';
				}

				return getFormattedNumber(value);
			}

			if (
				row.parentStrategy &&
				['grdlin', 'grddin'].includes(row.parentStrategy.StrategyCode)
			) {
				return '';
			}

			return getFormattedNumber(value);
		},
	},
	{
		flex: 0.8,
		field: 'ExecutedQtty',
		headerName: 'Qtd. Executada',
		type: 'number',
		renderCell: ({ value }) => {
			return getFormattedNumber(value, 0, true);
		},
	},
	{
		flex: 1,
		field: 'TargetDesc',
		headerName: 'Objetivo',
		type: 'string',
		renderCell: ({ value, row }) => {
			if (value) {
				if (
					[
						'grdlin',
						'grddin',
						'boffer',
						'twap',
						'mit',
						'sniper',
					].includes(row.StrategyCode) ||
					isNaN(Number(value))
				) {
					return value;
				} else {
					return getFormattedNumber(value, 2);
				}
			}
		},
	},
	{
		flex: 1,
		field: 'AvgPrice',
		headerName: 'Ex. Médio',
		type: 'number',
		renderCell: params =>
			isNaN(params.value)
				? '-'
				: getFormattedNumber(params.value, 5, true),
	},
	{
		flex: 1,
		field: 'Status',
		headerName: 'Status',
		type: 'singleSelect',
		valueOptions: statusFilterValues,
		getOptionValue: filter => filter.value,
		getOptionLabel: filter => filter.label,
		valueGetter: params =>
			params.row.hierarchy.length === 1
				? parseInt(params.row.OrdStatus ?? params.value)
				: parseInt(params.row.Status ?? params.value),
		renderCell: params => {
			const parsedStatus =
				params.row.hierarchy.length === 1
					? parseInt(params.row.OrdStatus ?? params.value)
					: parseInt(params.row.Status ?? params.value);

			const { label, color } = statusMapping[parsedStatus];

			return (
				params.row.hierarchy.length === 1 && (
					<ChipCell
						label={label}
						color={color}
						sx={{ width: '90%' }}
					/>
				)
			);
		},
	},
	{
		flex: 1,
		field: 'CreateDate',
		headerName: 'Data criação',
		type: 'dateTime',
		renderCell: params => {
			if (params.row.hierarchy.length === 1 && params.value) {
				return getFormattedDate(params.value, 'dd/MM/yyyy HH:mm:ss');
			}
		},
	},
	{
		flex: 1,
		field: 'TimeInForce',
		headerName: 'Tipo Validade',
		type: 'singleSelect',
		valueOptions: timeInForceFilterValues,
		getOptionValue: filter => filter.value,
		getOptionLabel: filter => filter.label,
		renderCell: params => {
			if (params.row.hierarchy.length > 1) {
				return '';
			}

			if (params.value) {
				const { color, label } = timeInForceValues[params.value];

				return (
					params.row.hierarchy.length === 1 && (
						<ChipCell
							label={label}
							color={color}
							isOutlined
							sx={{ minWidth: 60 }}
						/>
					)
				);
			}
		},
	},
	{
		flex: 0.8,
		field: 'ExpireDate',
		headerName: 'Validade',
		type: 'date',
		valueGetter: params => {
			if (params.row.hierarchy.length === 1 && params.value) {
				if (params.row.TimeInForce === '1') {
					return null;
				}

				return parseISO(params.value);
			}
		},
		renderCell: params => {
			if (params.row.hierarchy.length === 1 && params.value) {
				if (isValid(params.value)) {
					return format(params.value, 'dd/MM/yyyy');
				}

				return null;
			}
		},
	},
	{
		flex: 1,
		field: 'StrategyId',
		headerName: 'ID da estratégia',
		type: 'string',
		renderCell: params =>
			params.row.hierarchy.length === 1 && (
				<CopyCell value={params.value} />
			),
	},
	{
		flex: 1,
		field: 'ClOrdID',
		headerName: 'CLORDID',
		type: 'string',
		renderCell: params =>
			params.row.hierarchy.length === 1 && (
				<CopyCell value={params.value} />
			),
	},
	{
		flex: 1,
		field: 'StatusDate',
		headerName: 'Atualizado em',
		type: 'dateTime',
		renderCell: params => {
			if (params.value) {
				return getFormattedDate(params.value, 'dd/MM/yyyy HH:mm:ss');
			}
		},
	},
];
