import React from 'react';

import {
	Button,
	colors,
	FormControl,
	FormLabel,
	Grid,
	OutlinedInput,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import NumberFormat from 'react-number-format';

const COMPONENT_HEIGHT = 25;

const ButtonWithIcon = ({ children, sx = {}, disabled = false, ...rest }) => {
	return (
		<Button
			size="small"
			fullWidth
			variant="outlined"
			color="inherit"
			sx={{
				minWidth: 0,
				height: '100%',
				borderColor: colors.grey[600],
				cursor: disabled ? 'not-allowed' : 'pointer',
				...sx,
			}}
			{...rest}
		>
			{children}
		</Button>
	);
};

const StyledOutlinedInput = props => {
	return (
		<OutlinedInput
			{...props}
			size="small"
			fullWidth
			inputProps={{ style: { textAlign: 'right' } }}
			sx={{ borderRadius: 0, height: COMPONENT_HEIGHT }}
		/>
	);
};

export const NumericInput = ({
	value = 0,
	label = null,
	decimalScale,
	disabled,
	stepSize,
	minValue = null,
	maxValue = null,
	roundOnBlur,
	onChangeCallback,
	height = null,
	...rest
}) => {
	function getCalculatedValue(operation, currentValue, stepSize) {
		if (operation === 'add') {
			return currentValue + stepSize;
		}

		if (operation === 'subtract') {
			return currentValue - stepSize;
		}

		if (operation === 'round') {
			return Math.round(currentValue / stepSize) * stepSize;
		}
	}

	function handleAdjustValue(operation) {
		const parsedCurrentValue = parseFloat(value);
		const parsedStepSize = parseFloat(stepSize);

		if (!parsedStepSize) {
			return null;
		}

		const calculatedValue = getCalculatedValue(
			operation,
			parsedCurrentValue,
			parsedStepSize
		);

		if (
			![null, undefined].includes(minValue) &&
			calculatedValue < minValue
		) {
			return minValue;
		}

		if (maxValue && calculatedValue > maxValue) {
			return maxValue;
		}

		if (!isNaN(calculatedValue) && calculatedValue !== value) {
			return calculatedValue;
		}

		return null;
	}

	function handleChangeValue(event) {
		onChangeCallback(event.floatValue);
	}

	function handleIncreaseValue() {
		const adjustedValue = handleAdjustValue('add');

		if (adjustedValue) {
			onChangeCallback(adjustedValue);
		}
	}

	function handleDecreaseValue() {
		const adjustedValue = handleAdjustValue('subtract');

		if (![null, undefined].includes(adjustedValue)) {
			onChangeCallback(adjustedValue);
		}
	}

	function handleBlur() {
		const roundedValue = handleAdjustValue('round');

		if (roundOnBlur && roundedValue) {
			onChangeCallback(roundedValue);
		}
	}

	return (
		<>
			<FormControl fullWidth disabled={disabled}>
				{label && (
					<FormLabel
						sx={{ '&.Mui-focused': { color: 'white' }, mb: 0.5 }}
					>
						{label}
					</FormLabel>
				)}
				<Grid container item>
					<Grid xs={2}>
						<ButtonWithIcon
							onClick={handleDecreaseValue}
							disabled={disabled}
							sx={{
								borderRadius: '4px 0 0 4px',
								height: height ?? COMPONENT_HEIGHT,
							}}
						>
							<Remove size="small" />
						</ButtonWithIcon>
					</Grid>

					<Grid xs={8}>
						<NumberFormat
							customInput={StyledOutlinedInput}
							size="small"
							value={value}
							disabled={disabled}
							decimalScale={decimalScale}
							thousandSeparator="."
							decimalSeparator=","
							onValueChange={handleChangeValue}
							onBlur={handleBlur}
							height={height}
							{...rest}
						/>
					</Grid>

					<Grid xs={2}>
						<ButtonWithIcon
							onClick={handleIncreaseValue}
							disabled={disabled}
							sx={{
								borderRadius: '0 4px 4px 0',
								height: height ?? COMPONENT_HEIGHT,
							}}
						>
							<Add size="small" />
						</ButtonWithIcon>
					</Grid>
				</Grid>
			</FormControl>
		</>
	);
};
