import { format, isValid, parse } from 'date-fns';

function getDateFormat(date) {
	let baseFormat = 'yyyy-MM-dd';

	if (date.includes('-')) {
		if (/^\d{2}-\d{2}-\d{4}$/.test(date)) {
			baseFormat = 'dd-MM-yyyy';
		} else {
			baseFormat = 'yyyy-MM-dd';
		}
	} else if (date.includes('/')) {
		if (/\d{2}\/\d{2}\/\d{4}/.test(date)) {
			baseFormat = 'dd/MM/yyyy';
		} else {
			baseFormat = 'yyyy/MM/dd';
		}
	} else {
		baseFormat = 'yyyyMMdd';
	}

	return date.length > 10 ? `${baseFormat} HH:mm:ss` : baseFormat;
}

// Recebe uma data no tipo string e retorna como um objeto Date
export function getParsedDate(date) {
	if (date) {
		const dateFormat = getDateFormat(date);

		const parsedDate = parse(date, dateFormat, new Date());

		if (isValid(parsedDate)) {
			return parsedDate;
		}
	}

	return null;
}

// Retorna a data formatada no formato especificado ou a própria data se não for válida.
// - date: string => A data que você deseja formatar.
// - dateFormat: string => O formato desejado para a data formatada.
export function getFormattedDate(date, dateFormat) {
	if (date) {
		const parsedDate = getParsedDate(date);

		return isValid(parsedDate) ? format(parsedDate, dateFormat) : null;
	}

	return null;
}
